import config from 'configuration';

export default function getDeviceClassName(node = '') {
  const viewportWidth = node.innerWidth || node.clientWidth;
  // add also the tinymobile breakpoint class, if the questionnaire width is extremely small (important for icons theme)
  if (viewportWidth <= config.view.tinymobileBreakPoint) {
    return 'mobile tinymobile';
  }

  if (viewportWidth < config.view.mobileBreakPoint) {
    return 'mobile';
  }

  if (viewportWidth >= config.view.mobileBreakPoint && viewportWidth < config.view.tabletBreakPoint) {
    return 'tablet';
  }

  return '';
}
