import React from 'react';

function Label({ className, children, id, key }) {
  return (
    <label className={`Label ${className}`} id={id} key={key}>
      {children}
    </label>
  );
}

export default Label;
