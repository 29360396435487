if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

import Application from 'components/Application';
import 'core-js/es/map';
import 'core-js/es/promise';
import 'core-js/es/set';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import { isSameDomain } from 'utils';

function overwriteElementClassName(element) {
  element.className = 'FormClientInjector';
}

const SCRIPT_CLASS_NAME = '.ClientInjector';
const element = document.querySelector(SCRIPT_CLASS_NAME);
const sameDomain = isSameDomain(element.src);

function removeNullValues(obj) {
  return Object.keys(obj).reduce(
    (accumulator, key) =>
      obj[key] !== null
        ? {
            ...accumulator,
            [key]: obj[key],
          }
        : accumulator,
    {}
  );
}

const injectApp = () => {
  if (!element) return;

  overwriteElementClassName(element);

  const autoComplete = element.getAttribute('data-autocomplete');
  const autoJump = element.getAttribute('data-auto-jump');
  const backButton = element.getAttribute('data-back-button');
  const dataScrollToStepTop = element.getAttribute('data-scroll-to-step-top');
  const disableMergeDelay = element.getAttribute('data-disable-merge-delay');
  const offerType = element.getAttribute('data-offer-type');
  const phoneFormatting = element.getAttribute('data-phone-formatting') === 'true';
  const pushOnCompletion = element.getAttribute('data-push-on-completion');
  const remoteEmailValidation = element.getAttribute('data-remote-email-validation');
  const slideEnabled = element.getAttribute('data-slide-enabled');
  const theme = element.getAttribute('data-theme');
  const title = element.getAttribute('data-title');
  const useFsCdn = element.getAttribute('data-fs-cdn') !== 'no';
  const uuid = element.getAttribute('data-id');

  let scrollToStepTop;

  switch (dataScrollToStepTop) {
    case 'true':
      scrollToStepTop = true;
      break;
    case 'false':
      scrollToStepTop = false;
      break;
    default:
      scrollToStepTop = null;
  }

  let settings = {
    autoComplete,
    autoJump: autoJump || null,
    backButton,
    disableMergeDelay,
    isSameDomain: sameDomain,
    offerType,
    phoneFormatting,
    pushOnCompletion,
    remoteEmailValidation: remoteEmailValidation ? remoteEmailValidation !== 'false' : null,
    scrollToStepTop,
    slideEnabled: slideEnabled ? slideEnabled !== 'false' : null,
    theme,
    title,
    useFsCdn,
  };

  // filter out properties which are not defined at all, but keep the defined one with empty values
  settings = removeNullValues(settings);

  if (element.parentNode) {
    const container = document.createElement('div');
    container.id = 'questionnaire-wrapper';
    element.parentNode.appendChild(container);
    ReactDOM.render(<Application uuid={uuid} title={title} settings={settings} />, container);
  }
};

function init() {
  injectApp();

  if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('./index', injectApp);
  }
}

init();
