import React, { useState } from 'react';
import SafeHtml from '../../Utils';
import injectQuestionnaireVariables from '../../../utils/injectQuestionnaireVariables';

function Page({ submit, step, forwardRef, className = '' }) {
  const { body, action_text } = step;
  const [submitted, setSubmitted] = useState(false);

  function onSubmit(event) {
    event.preventDefault();
    if (submitted) {
      return;
    }
    setSubmitted(true);
    submit();
  }

  return (
    <form onSubmit={onSubmit} ref={forwardRef}>
      <div style={{ marginBottom: action_text ? 20 : 0 }} className={`Page ${className}`}>
        <SafeHtml html={injectQuestionnaireVariables(body)} />
      </div>

      {action_text && (
        <button data-qa="submit" className="Button" onClick={onSubmit} type="submit">
          <SafeHtml html={injectQuestionnaireVariables(action_text)} />
        </button>
      )}
    </form>
  );
}

export default Page;
