import React, { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { getUrlParameter, isNumeric } from 'utils';
import ErrorMessage from '../ErrorMessage';

const emailOverride = getUrlParameter('Email');

function Input({ name, type = 'text', placeholder, validated, min = '', max = '', pattern = '', onBlur, autoComplete }, ref) {
  const [dirty, setDirty] = useState(false);

  const {
    register,
    control,
    formState: { errors, isSubmitting },
    clearErrors,
    setValue,
  } = useFormContext();

  const value = useWatch({ control, name });
  const hasError = !!errors[name];
  const isValidatedAndValid = !hasError && value && validated && !dirty;

  useEffect(() => {
    if (isSubmitting && dirty) {
      setDirty(false);
    }
  }, [isSubmitting, dirty]);

  function handleChange(event) {
    if (isSubmitting) return;

    let newValue = event.target.value;
    const isEmail = name.toLowerCase() === 'email';

    if (isEmail && emailOverride) {
      newValue = emailOverride;
    }
    clearErrors(name);
    setValue(name, newValue);
    setDirty(true);
  }

  function handleBlur(event) {
    if (onBlur) {
      onBlur(event);
    }

    setDirty(false);
  }

  function isValidSpecialKey({ keyCode, ctrlKey, metaKey }) {
    if (ctrlKey || metaKey) {
      return true;
    }

    // special keycodes that we must accept as valid
    const validSpecialKeys = [
      8, // Backspace
      9, // Tab
      13, // Enter
      16, // Shift
      17, // Ctrl
      18, // Alt
      27, // Escape
      35, // End
      36, // Home
      37, // ArrowLeft
      38, // ArrowUp
      39, // ArrowRight
      40, // ArrowDown
      46, // Delete
      91, // CMD
    ];

    return validSpecialKeys.indexOf(keyCode) !== -1;
  }

  function onKeyDown(event) {
    if (event.target.type === 'number' && !(isNumeric(event.key) || isValidSpecialKey(event))) {
      event.preventDefault();
    }
  }

  return (
    <div
      className={`Input ${hasError ? 'invalid' : ''} ${isValidatedAndValid ? 'valid' : ''} ${
        isSubmitting && value && !validated ? 'spinner spinner--inline' : ''
      }`}
    >
      <input
        ref={(e) => {
          register(e);
          if (ref) {
            ref.current = e;
          }
        }}
        id={name}
        name={name}
        onKeyDown={onKeyDown}
        onChange={handleChange}
        type={type}
        min={min}
        max={max}
        autoComplete={autoComplete}
        pattern={pattern}
        className={`${type !== 'checkbox' ? 'form-control' : ''} ${hasError ? 'has-error' : ''}`}
        placeholder={placeholder}
        onBlur={handleBlur}
      />

      <label className="Input__label" htmlFor={name}>
        {placeholder}
      </label>

      <ErrorMessage name={name} />
      {isValidatedAndValid && <i className="icon fa-check" />}
    </div>
  );
}

export default React.forwardRef(Input);
