import { useEffect, useReducer } from 'react';

function reducer(state, action) {
  switch (action.type) {
    case 'start': {
      return {
        data: null,
        error: null,
        status: 'loading',
      };
    }
    case 'complete': {
      return {
        data: action.data,
        error: null,
        status: 'idle',
      };
    }
    case 'error': {
      return {
        data: null,
        error: action.error,
        status: 'error',
      };
    }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}

export default function useAsync(fn) {
  const [state, dispatch] = useReducer(reducer, {
    data: null,
    error: null,
    status: 'idle',
  });

  useEffect(() => {
    let cancelled = false;

    dispatch({ type: 'start' });
    fn().then(
      (data) => {
        if (cancelled) return;
        dispatch({ data, type: 'complete' });
      },
      (error) => {
        if (cancelled) return;
        dispatch({ error, type: 'error' });
      }
    );

    return () => {
      cancelled = true;
    };
  }, []);

  return state;
}
