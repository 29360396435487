import tracker from 'components/Tracker';
import {
  FORMCLIENT_EMAIL_CONSENT_FETCH_FAILED,
  FORMCLIENT_EMAIL_CONSENT_FOUND,
  FORMCLIENT_EMAIL_CONSENT_NOTFOUND,
  FORMCLIENT_PHONE_CONSENT_FETCH_FAILED,
  FORMCLIENT_PHONENUMBER_INVALID_FORMAT,
  FORMCLIENT_PHONENUMBER_LIBRARY_NOT_AVAILABLE,
  FORMCLIENT_PHONENUMBER_VALIDATION_APPROVED,
  FORMCLIENT_PHONENUMBER_VALIDATION_REJECTED,
} from 'components/Tracker/eventNames';
import appConfig from 'configuration';
import QUESTIONNAIRE_VARIABLES from 'constants/questionnaireVariables';
import { getAbsoluteResourcePath, isNumeric } from 'utils';
import fetchWithTimeout from 'utils/fetchWithTimeout';
import { setReservedQuestionnaireVariable } from 'utils/setQuestionnaireVariables';
import * as yup from 'yup';
import ZipCodes from './zipCodes';

const EMAIL_REMOTE_VALIDATION_ERRORS = ['Undeliverable'];
const PHONE_REMOTE_VALIDATION_ERRORS = ['inactive'];

const cache = {
  email: {
    isValid: null,
    value: null,
  },
  phone: {
    formattedValue: null,
    hasEmailWithConsent: null,
    input: null,
    isValid: false,
  },
  postalCode: {
    isValid: null,
    value: null,
  },
};

function validateRemote(payload, errors) {
  payload.gid = window.sessionLayer.session.getGid();
  payload.url = window.location.href;
  payload.requester = 'FormClient';

  function isSuccessful(response) {
    const { status, errors: responseErrors } = response;
    const isValidError = !!errors && responseErrors && !responseErrors.some((error) => errors.includes(error.code));

    return status === 'success' || isValidError;
  }

  return new Promise((resolve) => {
    fetchWithTimeout(
      getAbsoluteResourcePath('validationService'),
      {
        body: JSON.stringify(payload),
        cache: 'no-cache',
        method: 'POST',
        mode: 'cors',
      },
      appConfig.validationTimeout
    )
      .then((response) => response.json())
      .then((response) => {
        resolve(isSuccessful(response));
      })
      .catch(() => {
        resolve(true);
      });
  });
}

function isActivePhone(value, countryCode) {
  const payload = {
    fields: [
      {
        extras: {
          country_code: countryCode,
        },
        name: 'Phone',
        options: ['melissa'],
        validation: 'phone',
        value,
      },
    ],
  };

  return validateRemote(payload, PHONE_REMOTE_VALIDATION_ERRORS);
}

function fetchHasEmailWithConsent(phone, locale) {
  const gid = window.sessionLayer.session.getGid();
  const url = window.location.href;
  const payload = {
    gid,
    locale,
    phone,
    requester: 'FormClient',
    url,
  };

  return new Promise((resolve) => {
    fetchWithTimeout(
      getAbsoluteResourcePath('emailConsent'),
      {
        body: JSON.stringify(payload),
        cache: 'no-cache',
        method: 'POST',
        mode: 'cors',
      },
      appConfig.validationTimeout
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }

        return response.json();
      })
      .then((response) => {
        const { hasEmailWithConsent } = response;
        const logMessage = hasEmailWithConsent ? FORMCLIENT_EMAIL_CONSENT_FOUND : FORMCLIENT_EMAIL_CONSENT_NOTFOUND;
        tracker.log(logMessage, {
          phone,
        });

        resolve(hasEmailWithConsent);
      })
      .catch((error) => {
        tracker.log(FORMCLIENT_EMAIL_CONSENT_FETCH_FAILED, { error: error.message, phone });
        resolve(false);
      });
  });
}

function setHasEmailWithConsentQuestionnaireVariable(rawValue) {
  const value = rawValue ? 'Yes' : 'No';

  setReservedQuestionnaireVariable(QUESTIONNAIRE_VARIABLES.USER_HAS_EMAIL_WITH_CONSENT, value);
}

yup.addMethod(yup.string, 'phoneRule', function method(locale = '', message = '') {
  return this.test('phone-rule', message, async function assertionFn(input) {
    if (!input) {
      return true;
    }

    const countryCode = locale.slice(3, 5);
    const isUs = countryCode === 'US';
    const isCa = countryCode === 'CA';
    const isDe = countryCode === 'DE';

    if (input === cache.phone.input) {
      if (isUs || isCa || isDe) {
        setHasEmailWithConsentQuestionnaireVariable(cache.phone.hasEmailWithConsent);
      }

      return cache.phone.isValid || this.createError({ message });
    }

    cache.phone.input = input;

    if (!window.libphonenumber) {
      tracker.log(FORMCLIENT_PHONENUMBER_LIBRARY_NOT_AVAILABLE, { phone: input });
      cache.phone.isValid = true;
      return cache.phone.isValid;
    }

    const parsedInput = window.libphonenumber.parsePhoneNumberFromString(input, countryCode);

    if (!parsedInput) {
      tracker.log(FORMCLIENT_PHONENUMBER_INVALID_FORMAT, {
        phone: input,
        validationType: 'client',
      });
      cache.phone.isValid = false;
      return cache.phone.isValid;
    }

    if (parsedInput.number === cache.phone.formattedValue) {
      return cache.phone.isValid;
    }

    cache.phone.formattedValue = parsedInput.number;
    cache.phone.isValid = parsedInput.isValid();

    if (!cache.phone.isValid) {
      tracker.log(FORMCLIENT_PHONENUMBER_VALIDATION_REJECTED, {
        phone: cache.phone.formattedValue,
        validationType: 'client',
      });
      return cache.phone.isValid;
    }

    if (isUs || isCa) {
      [cache.phone.hasEmailWithConsent, cache.phone.isValid] = await Promise.all([
        fetchHasEmailWithConsent(cache.phone.formattedValue, locale),
        isActivePhone(cache.phone.formattedValue, countryCode),
      ]);

      setHasEmailWithConsentQuestionnaireVariable(cache.phone.hasEmailWithConsent);
    } else if (isDe) {
      cache.phone.hasEmailWithConsent = await fetchHasEmailWithConsent(cache.phone.formattedValue, locale);

      setHasEmailWithConsentQuestionnaireVariable(cache.phone.hasEmailWithConsent);
    }

    if (cache.phone.isValid) {
      tracker.log(FORMCLIENT_PHONENUMBER_VALIDATION_APPROVED, {
        phone: cache.phone.formattedValue,
      });

      if (isUs || isCa || isDe) {
        try {
          await window.sessionLayer.customerDataService.fetchPhoneConsent(cache.phone.formattedValue);
        } catch (err) {
          tracker.log(FORMCLIENT_PHONE_CONSENT_FETCH_FAILED, {
            error: err.message,
            phone: cache.phone.formattedValue,
          });
        }
      }
    } else {
      tracker.log(FORMCLIENT_PHONENUMBER_VALIDATION_REJECTED, {
        phone: cache.phone.formattedValue,
        validationType: 'remote',
      });
    }

    return cache.phone.isValid;
  });
});

// FOR CACHING
const zipCodes = new ZipCodes({ customFetch: fetchWithTimeout });

let res = null;
let prevPostalCode = '';
let validPostalCode = false;

yup.addMethod(yup.string, 'postalCodeRuleCanada', function method(message = '') {
  return this.test('postal-code-rule-canada', message, async (value) => {
    if (!value) return true;

    const postalCodeValue = value.toLowerCase();
    if (postalCodeValue.length >= 3) {
      try {
        res = prevPostalCode === postalCodeValue ? res : zipCodes.checkPostalCode(postalCodeValue);
        prevPostalCode = postalCodeValue;

        const response = await res;
        const { Error, PostalCode = '' } = response;
        const postalCode = response.PostalCode && PostalCode.split(' ').join('');

        validPostalCode =
          Error || Object.keys(response).length === 0
            ? false
            : !!postalCode.find((item) => item.postalCode.toLowerCase() === postalCodeValue);
      } catch (error) {
        // TODO: Error handling
        // if (console && console.log) {
        //   console.log('[FormClient] Remote postal code validation failed');
        // }
        validPostalCode = true;
      }
    }

    return validPostalCode;
  });
});

yup.addMethod(yup.string, 'postalCodeRuleSwitzerland', function method(message = '') {
  return this.test('postal-code-rule-switzerland', message, (value) => {
    if (!value) return true;

    const valueWithoutSpaces = value.replace(/\s/g, '');

    const hasCorrectLength = valueWithoutSpaces.length === 4;
    if (!hasCorrectLength) return false;

    const allCharsAreNumbers = valueWithoutSpaces.split('').every((char) => !Number.isNaN(Number(char)));
    if (!allCharsAreNumbers) return false;

    const valueAsNumber = Number(valueWithoutSpaces);
    const isAtLeastLowestPostalCode = valueAsNumber >= 1000;
    const isLiechtensteinPostalCode = valueAsNumber >= 9480 && valueAsNumber <= 9499;
    if (!isAtLeastLowestPostalCode || isLiechtensteinPostalCode) return false;

    return true;
  });
});

yup.addMethod(yup.string, 'postalCodeRuleNetherlands', function method(message = '') {
  return this.test('postal-code-rule-netherlands', message, (value) => {
    if (!value) return true;

    const valueWithoutSpaces = value.replace(/\s/g, '');

    const hasCorrectLength = valueWithoutSpaces.length === 6;
    if (!hasCorrectLength) return false;

    const firstFourChars = valueWithoutSpaces.slice(0, 4);
    const firstFourCharsAreNumbers = !Number.isNaN(Number(firstFourChars));
    if (!firstFourCharsAreNumbers) return false;

    const lastTwoChars = valueWithoutSpaces.slice(4, 6);
    const lastTwoCharsAreLetters = lastTwoChars.split('').every((char) => char.toLowerCase() !== char.toUpperCase());
    if (!lastTwoCharsAreLetters) return false;

    const lastTwoCharsAreNotBlacklisted = !['SA', 'SD', 'SS'].includes(lastTwoChars.toUpperCase());
    if (!lastTwoCharsAreNotBlacklisted) return false;

    return true;
  });
});

yup.addMethod(yup.string, 'emailRule', function method(remoteEmailValidation, message = '', locale = '') {
  return this.test('email-rule', message, async function assertionFn(value) {
    if (!value) return true;

    const regex =
      /^(?:(?:[^<>()[\]\\.,;:\s@"]+(?:\.[^<>()[\]\\.,;:\s@"]+)*)|(?:".+"))@(?:(?:\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(?:(?:[a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // https://regex101.com/r/l34nKs/1

    const emailFormatValid = regex.test(value);

    if (!remoteEmailValidation || !emailFormatValid) {
      return emailFormatValid || this.createError({ message });
    }

    try {
      if (value === cache.email.value) {
        return cache.email.isValid;
      }
      cache.email.value = value;
      const countryCode = locale.slice(3, 5);
      const payload = {
        fields: [
          {
            extras: {
              country_code: countryCode,
            },
            name: 'Email',
            options: ['melissa'],
            validation: 'email',
            value,
          },
        ],
      };

      cache.email.isValid = validateRemote(payload, EMAIL_REMOTE_VALIDATION_ERRORS);
      const isValid = await cache.email.isValid;

      return isValid || this.createError({ message });
    } catch (e) {
      return true;
    }
  });
});

async function isValidPostalCodePrivate(value, locale) {
  const countryCode = locale.slice(3, 5);
  const isValidFormat = value.length === 6 && isNumeric(value);

  if (!isValidFormat) return Promise.resolve(isValidFormat);

  const payload = {
    fields: [
      {
        extras: {
          country_code: countryCode,
        },
        name: 'Postal Code',
        options: ['allowlist'],
        validation: 'postalcode',
        value,
      },
    ],
  };

  const isValid = await validateRemote(payload);

  return isValid;
}

async function isValidPostalCode(value, locale) {
  if (!cache.postalCode.isValid) {
    cache.postalCode.isValid = isValidPostalCodePrivate(value, locale);
  }

  return cache.postalCode.isValid;
}

yup.addMethod(yup.string, 'postalCodeRuleIndia', function method(locale, message = '') {
  return this.test('postal-code-rule-india', message, async function assertionFn(rawValue) {
    if (!rawValue) return true;

    const value = rawValue.toLowerCase();

    if (value === cache.postalCode.value) {
      return cache.postalCode.isValid || this.createError({ message });
    }

    cache.postalCode = { value };

    const isValid = await isValidPostalCode(value, locale);

    return isValid || this.createError({ message });
  });
});

export default cache;
