import React, { memo } from 'react';
import Page from '../Page';

function StepLoader({ loaderVisible, step, submit }) {
  return (
    <div data-test={step.uuid} style={{ display: loaderVisible ? '' : 'none' }}>
      <Page submit={submit} step={step} />
    </div>
  );
}

export default memo(StepLoader);
