import { getAbsoluteResourcePath } from '../../utils';

// TODO: use 1 tracker on the page. E.g. from sessionLayer
const tracker = {
  log: (eventName, data = {}) => {
    const payload = {
      environment: process.env.NODE_ENV,
      gid: window.sessionLayer && window.sessionLayer.session.gid,
      locale: window.sessionLayer?.locale,
      name: eventName,
      path: document.location.pathname,
      referrer: document.referrer,
      testId: window.sessionLayer && window.sessionLayer.testId,
      ua: navigator.userAgent,
      url: document.location.href,
      ...data,
    };

    try {
      fetch(getAbsoluteResourcePath('trackerService'), {
        body: JSON.stringify({
          event: payload,
        }),
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
        },
        method: 'POST',
        mode: 'no-cors',
      });
    } catch (e) {
      // TODO: Error handling
      // console.error(e);
    }
  },
  track: (eventName) => {
    try {
      window.performanceTracker.mark(eventName);
    } catch (e) {
      // TODO: Error handling
      // console.log(e);
    }
  },
};

export default tracker;
