import QUESTIONNAIRE_VARIABLES from 'constants/questionnaireVariables';
import SALESFORCE_FIELDS from 'constants/salesforceFields';
import { isQuestion } from 'utils';

function setQuestionnaireVariable(name, value) {
  window.sessionLayer.questionnaireVariables.set(name, value);
}

export function setReservedQuestionnaireVariable(name, value) {
  window.sessionLayer.questionnaireVariables.setReserved(name, value);
}

function setQuestionnaireVariablesDependingOnField(values) {
  const lastNameIsSet = Object.prototype.hasOwnProperty.call(values, SALESFORCE_FIELDS.LAST_NAME);

  if (lastNameIsSet) {
    const value = values.LastName;
    const cleanString = value.trim().replace('s+', ' ');

    const words = cleanString.split(' ');

    const surname = words.pop();
    const name = words.join(' ');

    setQuestionnaireVariable(QUESTIONNAIRE_VARIABLES.USER_FIRST_NAME, name);
    setQuestionnaireVariable(QUESTIONNAIRE_VARIABLES.USER_LAST_NAME, surname);
  }
}

function setQuestionnaireVariablesDependingOnAnswer(step) {
  const selectedAnswer = step.answers.find((answer) => answer.checked);
  const answerVariables = selectedAnswer?.variables || [];

  answerVariables.forEach(({ name, value }) => {
    setQuestionnaireVariable(name, value);
  });
}

export default function setQuestionnaireVariables({ step, values }) {
  if (isQuestion(step)) {
    setQuestionnaireVariablesDependingOnAnswer(step);
  }

  setQuestionnaireVariablesDependingOnField(values);
}
