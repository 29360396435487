import React from 'react';
import { isForm, isPage } from '../../utils';
import { useSlider } from '../SliderProvider';

function Pagination() {
  const { index: activeIndex = 0, index, steps = [] } = useSlider();
  const slidingInStep = steps[index];
  const hidden = isForm(slidingInStep) || isPage(slidingInStep);
  const items = steps.filter((item) => !isPage(item) && !isForm(item));

  return !hidden ? (
    <div className="Pagination">
      <ul className="List">
        {items.map((item, currentIndex) => (
          <li key={item.uuid} className={`ListItem ${currentIndex === activeIndex ? 'active' : ''}`} />
        ))}
      </ul>
    </div>
  ) : null;
}

export default Pagination;
