import { ACTION_NAMES, createActions } from 'actions';
import useSliderHeightCalculation from 'components/Questionnaire/hooks';
import USER_ACTIONS from 'constants/userActions';
import { useEvent } from 'hooks';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useReducerAsync } from 'use-reducer-async';
import { createBEMClass, getIndexByHash, getTransitionEventName, scrollToHeader } from 'utils';
import statusTypes from '../Application/statusTypes';
import { getLoaderByStep } from './loaders';

const SliderContext = createContext({});
SliderContext.displayName = 'SliderContext';

export function useSlider() {
  return useContext(SliderContext);
}

function shouldShowBackButton(isLead, state, index, backButton) {
  if (isLead) return false;
  if (!backButton) return false;
  if (index === 0) return false;

  return state.prevIndex < index;
}

function createReducer({ settings, loaders }) {
  return function reducer(state, action) {
    switch (action.type) {
      case 'SAVE_VALUES': {
        const { values = {} } = action;

        if (Object.prototype.hasOwnProperty.call(values, 'Express_written_consent__m')) {
          values.Express_written_consent__c = values.Express_written_consent__m ? 'consent provided' : 'not set';

          delete values.Express_written_consent__m;
        }

        if (Object.prototype.hasOwnProperty.call(values, 'Secondary_Opt_in__m')) {
          values.Secondary_Opt_in__c = values.Secondary_Opt_in__m ? 'agree' : 'disagree';

          delete values.Secondary_Opt_in__m;
        }

        if (Object.prototype.hasOwnProperty.call(values, 'DOI_Status__m')) {
          values.DOI_Status__c = values.DOI_Status__m ? 'Implicit opt-in' : 'Opt-out';

          delete values.DOI_Status__m;
        }

        const userAnswered = Object.values(values).some((item) => item !== null);
        const userAction = userAnswered ? USER_ACTIONS.SUBMIT : USER_ACTIONS.SKIP;

        return {
          ...state,
          leadData: {
            ...state.leadData,
            ...values,
          },
          userAction,
        };
      }
      case 'SET_LOADER': {
        const { loader } = action;
        return {
          ...state,
          loader,
        };
      }
      case 'SHOW_LOADER': {
        return {
          ...state,
          loaderVisible: true,
        };
      }
      case 'SKIP': {
        const { nextIndex } = action;
        const { slideEnabled } = settings;
        const index = nextIndex || Math.min(state.index + 1, state.steps.length - 1);

        return {
          ...state,
          inViewIndex: slideEnabled ? index : state.inViewIndex,
          index,
          loaderVisible: slideEnabled ? state.loaderVisible : false,
          prevIndex: state.index,
          transition: false,
          valid: true,
          visibleTransitionIndexes: [...state.visibleTransitionIndexes, state.nextIndex],
        };
      }
      case 'GO_TO': {
        const { nextIndex } = action;
        const { slideEnabled } = settings;
        const index = nextIndex || Math.min(state.index + 1, state.steps.length - 1);

        return {
          ...state,
          index,
          loaderVisible: slideEnabled ? state.loaderVisible : false,
          prevIndex: state.index,
          transition: slideEnabled,
          valid: true,
          visibleTransitionIndexes: [...state.visibleTransitionIndexes, state.nextIndex],
        };
      }
      case 'AFTER_STEP_CHANGED': {
        const index = action.newIndex || state.index;
        const isLead = window.sessionLayer.session.isValid();
        const { backButton } = settings;

        return {
          ...state,
          inViewIndex: index,
          loader: getLoaderByStep(loaders, state.steps[index]),
          loaderVisible: false,
          showPreviousButton: shouldShowBackButton(isLead, state, index, backButton),
          transition: false,
          userAction: null,
          visibleTransitionIndexes: [],
        };
      }
      case 'GO_TO_PREVIOUS': {
        const { prevIndex } = action;
        const { slideEnabled } = settings;

        return {
          ...state,
          index: prevIndex,
          prevIndex: state.index,
          showPreviousButton: false,
          transition: slideEnabled,
          userAction: USER_ACTIONS.GO_BACK,
          valid: true,
          visibleTransitionIndexes: [prevIndex, ...state.visibleTransitionIndexes],
        };
      }
      default: {
        throw new Error(`Unhandled action type: ${action.type}`);
      }
    }
  };
}

function SliderProvider({ data, status, setStatus, onViewIndexChange, children }) {
  const { settings } = data;
  const initialIndex = getIndexByHash() || 0;
  const initialState = useMemo(
    () => ({
      formUniqueIdentifier: data.formUniqueIdentifier,
      inViewIndex: initialIndex,
      index: initialIndex,
      leadData: {},
      loader: getLoaderByStep(data.loaders, data.steps[initialIndex]),
      loaderVisible: false,
      loaders: data.loaders,
      locale: data.locale,
      nextIndex: null,
      prevIndex: null,

      showPreviousButton: false,

      steps: data.steps,

      /**
       * Used to track the transition phase between slider moving animation.
       * Allows making the prev and the next steps visible on animation start
       * and hide them when the animation is finished
       * The reason is the bug with tabulation: If the input in the future steps is visible
       * pressing the "tab" key leads to putting the input field into the viewport.
       */
      transition: false,

      uuid: data.uuid,

      valid: true,
      /**
       * During a sliding transition the class "visible" is added to all of the <Steps/> which are valid for sliding.
       * Means:
       * Slider has 10 steps: 0 1 2 3 4 5 6 7 8 9,
       * e.g. The expected showing steps would be: 0 -> 5 -> 6 -> 9 (cuz we have follow logic)
       * if the "next" btn is clicked fast multiple times the "visible" class must be added to 0, 5, 6, 9
       */
      visibleTransitionIndexes: [],
    }),
    []
  );
  const memoizedReducer = useCallback(createReducer({ loaders: data.loaders, settings }), []);
  const memoizedActions = useMemo(() => createActions(data), []);

  const [state, dispatch] = useReducerAsync(memoizedReducer, initialState, memoizedActions);
  const [stepsRefs, setStepsRefs] = useState({});

  const sliderRef = useRef(null);
  const stepContainer = useRef(null);
  const footerRef = useRef(null);
  const formRef = useRef(null);
  const { classes } = state.steps[state.inViewIndex];
  const refs = useRef({});
  const { height: sliderHeights } = useSliderHeightCalculation(settings, data.steps, stepsRefs, sliderRef, footerRef);

  // Register every Step's ref. Use them in "useSliderHeightCalculation" to get a common height
  function register({ name }) {
    return (ref) => {
      const steps = refs.current;

      if (!steps[name]) {
        steps[name] = ref;
        setStepsRefs(refs.current);
      }
    };
  }

  const beforeStepChange = () => {
    const { scrollToStepTop = true } = settings;
    if (scrollToStepTop) {
      scrollToHeader(sliderRef.current);
    }
  };

  const previous = async () => {
    const { formUniqueIdentifier } = data;

    beforeStepChange();
    dispatch({ type: 'GO_BACK' });
    window.sessionLayer.leadHandler.pop(formUniqueIdentifier);
  };

  const onHashChange = () => {
    const { steps } = state;
    const newIndex = getIndexByHash();
    const maxIndex = steps.length - 1;
    const nextIndex = newIndex >= 0 && newIndex <= maxIndex ? newIndex : null;

    if (newIndex) {
      dispatch({ nextIndex, type: 'GO_TO' });
    }
  };

  const onDataInBufferPushed = useCallback((bufferAnswer) => {
    dispatch({ answer: bufferAnswer, type: ACTION_NAMES.SKIP_QUESTION });
  }, []);

  const onKeyDownHandler = useCallback(
    async (event) => {
      // Return/Enter
      if (event.keyCode === 13) {
        event.preventDefault();
        try {
          formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
        } catch (error) {
          //
        }
      }
    },
    [formRef]
  );

  useEffect(() => {
    const { prevIndex } = state;
    const {
      settings: { slideEnabled },
    } = data;
    if (!slideEnabled && prevIndex >= 0 && prevIndex !== null) {
      // if animation is enabled then SUBMIT_SUCCESS is called on transition end.
      dispatch({
        callback: (index) => {
          onViewIndexChange(index);
        },
        type: ACTION_NAMES.SUBMIT_SUCCESS,
      });
    }
  }, [state.index]);

  function getBufferAnswers(key) {
    const answeredQuestionsBeforeInit = window.sessionLayer.leadHandler.buffer.getData();
    return answeredQuestionsBeforeInit[key] || [];
  }

  function checkQuestionsToSkip() {
    const { formUniqueIdentifier } = data;
    const bufferAnswers = getBufferAnswers(formUniqueIdentifier);

    if (bufferAnswers.length > 0) {
      bufferAnswers.forEach((bufferAnswer) => {
        dispatch({ answer: bufferAnswer, type: ACTION_NAMES.SKIP_QUESTION });
      });
    }
  }

  useEffect(() => {
    window.sessionLayer.leadHandler.on('dataPushed', onDataInBufferPushed);
    checkQuestionsToSkip();
    setStatus(statusTypes.ready);

    return () => {
      window.sessionLayer.leadHandler.off('dataPushed', onDataInBufferPushed);
    };
  }, []);

  useEffect(() => {
    if (status === statusTypes.ready) {
      dispatch({ type: ACTION_NAMES.INIT });
    }
  }, [status]);

  useEffect(() => {
    const { index, prevIndex } = state;
    if (prevIndex !== null && prevIndex !== index) {
      dispatch({ type: ACTION_NAMES.PUSH_TO_DATA_HANDLER });
    }
  }, [state.index, state.prevIndex]);

  const onTransitionEnd = useCallback(
    (event) => {
      const { propertyName, type } = event;
      const {
        settings: { slideEnabled },
      } = data;
      const isTransformEvent = propertyName === 'transform' || propertyName === '-webkit-transform';
      // Pupperteer doesn't provide propertyName. Tests are failing, cuz SUBMIT_SUCCESS was not executed because of that
      const isTransitionPuppeteerEvent = !propertyName && type === 'transitionend';

      if (type === 'transitionend' && (isTransformEvent || isTransitionPuppeteerEvent) && slideEnabled) {
        dispatch({
          callback: (index) => {
            onViewIndexChange(index);
          },
          data,
          type: ACTION_NAMES.SUBMIT_SUCCESS,
        });
      }
    },
    [state.index]
  );

  const setLoader = useCallback(
    (loaderUUID) => {
      const { loaders } = state;
      dispatch({ loader: loaders.find((item) => item.uuid === loaderUUID), type: 'SET_LOADER' });
    },
    [dispatch, state.loaders]
  );

  useEvent('keydown', onKeyDownHandler);
  useEvent('hashchange', onHashChange);
  useEvent(getTransitionEventName(), onTransitionEnd, stepContainer?.current);

  function submitLoader() {
    dispatch({ type: ACTION_NAMES.SUBMIT_LOADER });
  }

  const submit = useCallback(
    (values = {}) => {
      beforeStepChange();
      dispatch({ type: ACTION_NAMES.SUBMIT, values });
    },
    [beforeStepChange, dispatch]
  );

  const value = useMemo(
    () => ({
      footerRef,
      formRef,
      register,
      stepContainer,
      ...state,
      dispatch,
      locale: data.locale,
      previous,
      setLoader,
      settings: data.settings,
      sliderHeights,
      submit,
      submitLoader,
    }),
    [state, sliderHeights]
  );

  return (
    <SliderContext.Provider value={value}>
      <div
        ref={sliderRef}
        className={`Slider ${createBEMClass('Slider', classes)} ${settings.slideEnabled ? 'fx-slide' : ''}`}
        style={{ minHeight: sliderHeights.minHeight }}
      >
        {status === statusTypes.ready && children}
      </div>
    </SliderContext.Provider>
  );
}

export default SliderProvider;
