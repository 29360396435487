import config from 'configuration';

const safeDecodeURIComponent = (string) => {
  try {
    return decodeURIComponent(string);
  } catch (err) {
    // TODO: Error handling
    // if (console && console.log) {
    //   console.log(err);
    // }
  }

  return string;
};

export const getUrlParameter = (searchParameter) => {
  const pageUrl = safeDecodeURIComponent(window.location.search.substr(1));

  if (pageUrl !== '') {
    const urlVariable = pageUrl.split('&');
    let parameterName;

    for (let i = 0; i < urlVariable.length; i += 1) {
      parameterName = urlVariable[i].split('=');

      if (parameterName[0] === searchParameter) {
        if (parameterName[1] !== undefined) {
          return parameterName[1];
        }

        return '';
      }
    }
  }

  return '';
};

export const isNumeric = (value = 0) => !Number.isNaN(parseFloat(value)) && Number.isFinite(parseFloat(value));

export function isEmptyString(str) {
  return str == null || str.trim() === '';
}

export const createLink = (url, className = '') => {
  const element = document.createElement('link');
  element.rel = 'stylesheet';
  element.type = 'text/css';
  element.href = url;
  if (className !== '') {
    element.setAttribute('class', className);
  }
  return element;
};

export const createScript = ({ url, async = false, className = '', defer = false, onload, id = '' }) => {
  const element = document.createElement('script');

  if (onload) {
    element.onload = onload;
  }

  element.id = id;
  element.src = url;
  element.async = async;

  if (className) {
    element.setAttribute('class', className);
  }

  element.defer = defer;
  return element;
};

export const createSelectorID = (options = {}) => {
  const { id = '', salesforce = '' } = options;

  return `${id}_${salesforce}`.replace(/[^a-zA-Z0-9_]/g, '_').replace(/_+/g, '_');
};

export const getDateStringWithTimeZone = () => {
  const date = new Date().toString();
  // remove double timezone information from string
  return date.replace(/\(([^)]+)\)/, '');
};

export const scrollToHeader = (slider) => {
  if (document.body.clientWidth <= config.view.mobileBreakPoint && window.scrollTo) {
    if (slider) {
      window.scrollTo(window.scrollX, slider.getBoundingClientRect().top + window.scrollY);
    }
  }
};

export const triggerEvent = (el, eventName, options = {}) => {
  const event = new CustomEvent(eventName, { bubbles: true, ...options });
  let elementToDispatchEvent = el;

  if (typeof elementToDispatchEvent === 'string') {
    elementToDispatchEvent = document.querySelector(el);
  }

  if (!elementToDispatchEvent) {
    return;
  }

  elementToDispatchEvent.dispatchEvent(event);
};
/**
 * fire an external event to SessionLayer
 */
export const fireExternalEvent = (eventName) => {
  if (typeof eventName === 'string' && eventName.trim() !== '') {
    const safeEventName = eventName.trim().replace(/[^a-zA-Z0-9]+/gi, '_');
    triggerEvent('#SessionLayer', safeEventName);
  }
};

export const normalize = (value) => {
  try {
    // workaround for Salutatiton, cuz value is stringified Array at the beginning
    const parsedValue = JSON.parse(value) || null;
    if (Array.isArray(parsedValue)) {
      return null;
    }
  } catch (e) {
    // TODO: Error handling
    // console.log(e);
  }

  return value === '[]' || value === '{}' ? '' : value;
};

export const cssValueToInt = (value = '') => parseInt(value.replace(/px/gi, ''), 10) || 0;

export const normalizeBooleans = (data) =>
  Object.keys(data || {}).reduce((obj, key) => {
    const value = data[key];

    if (value === 'true') {
      data[key] = true;
    } else if (value === 'false') {
      data[key] = false;
    } else {
      data[key] = value;
    }
    return obj;
  }, data);

/**
 * @param url - src of app.js script
 * @returns {boolean}
 */
export const isSameDomain = (url) => {
  // Disable the relative paths feature on stand-alone pages
  // like formclient.cdn.audibene.net and all non-https sources
  // now it's the same as resource path. E.g. https://formclient.cdn.audibene.net
  const absoluteFormClientPath = config.resources.absolute.basePath;
  const isStandalonePage = absoluteFormClientPath.indexOf(document.location.host) !== -1;
  const isHttps = document.location.protocol === 'https:';

  if (isStandalonePage || !isHttps) {
    return false;
  }

  return url.indexOf(document.location.host) !== -1;
};

export const getAbsoluteResourcePath = (name) => config.resources.absolute[name];

export const getRelativeResourcePath = (name) => config.resources.relative[name];

export const getResourcePath = (name, sameDomain = false) => (sameDomain ? getRelativeResourcePath(name) : getAbsoluteResourcePath(name));

export const getTransitionEventName = () => {
  let name = 'ontransitionend';

  if ('ontransitionend' in window) {
    // Firefox
    name = 'transitionend';
  } else if ('onwebkittransitionend' in window) {
    // Chrome/Saf (+ Mobile Saf)/Android
    name = 'webkitTransitionEnd';
  } else if (navigator.appName === 'Opera') {
    // TODO: Check deprecated
    // Opera
    name = 'oTransitionEnd';
  } else {
    // IE
    name = 'transitionend';
  }

  return name;
};

export const throttle = (callback, delay) => {
  let timeoutHandler = null;

  return function fn() {
    if (timeoutHandler == null) {
      timeoutHandler = setTimeout(() => {
        callback();
        clearInterval(timeoutHandler);
        timeoutHandler = null;
      }, delay);
    }
  };
};

export const isForm = ({ type }) => type === 'form';
export const isQuestion = ({ type }) => type === 'question';
export const isPage = ({ type }) => type === 'page';

export const objectToQueryParams = (obj) =>
  Object.keys(obj)
    .map((param) => {
      const value = obj[param];
      return `${param}=${value}`;
    })
    .join('&');

export function shouldShowFooter(step, backButton, autoJump) {
  const noBackButton = backButton === 'hide';
  const noNextButton = autoJump === 'true-no-btn';

  if (noBackButton && noNextButton) return false;
  if (isPage(step)) return false;

  return true;
}

// A/B test for US
export async function pushLeadScoreToDataLayer(formUniqueIdentifier, leadData) {
  const isSetScore = localStorage.getItem('score');

  if (isSetScore) return;

  try {
    const score = await window.sessionLayer.leadHandler.getLeadScore(formUniqueIdentifier, leadData);

    if (score) {
      window.sessionLayer.gtm.push(score);
      window.sessionLayer.leadHandler.push(formUniqueIdentifier, {
        Base_Score__c: score.leadscore || null,
      });
      try {
        localStorage.setItem('score', JSON.stringify(score));
      } catch (e) {
        // TODO: Error handling
        // console.error(e);
      }
    }
  } catch (e) {
    // TODO: Error handling
  }
}

export function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function createBEMClass(blockClass, modifier = '') {
  if (!modifier) return '';

  const modifiersArr = modifier.split(' ');
  return modifiersArr
    .filter(Boolean)
    .map((item) => `${blockClass}--${item}`)
    .join(' ');
}

function getHash(regExp) {
  const matches = window.location.hash.match(regExp);

  if (matches !== null && typeof matches === 'object') {
    return matches.pop();
  }

  return '';
}

export function getIndexByHash() {
  const hash = getHash(/fc:(\d+)/);

  if (!hash) {
    return null;
  }

  return parseInt(hash, 10);
}

export function isEqualArray(_arr1, _arr2) {
  if (_arr1.length !== _arr2.length) {
    return false;
  }

  const arr1 = _arr1.concat().sort();
  const arr2 = _arr2.concat().sort();

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
}

export function countryFromLocale(locale) {
  const localeShape = /^[a-z]{2}[-_][A-Z]{2}$/;
  if (!localeShape.test(locale)) {
    return null;
  }

  return locale.slice(-2);
}
