import React from 'react';
import { createBEMClass } from '../../utils';
import injectQuestionnaireVariables from '../../utils/injectQuestionnaireVariables';

function QuestionnaireHeader({ title, stepClasses, description = '' }) {
  return (
    <div className={`QuestionnaireHeader ${createBEMClass('QuestionnaireHeader', stepClasses)}`}>
      <span className="TextLabel title">{injectQuestionnaireVariables(title)}</span>
      {description && (
        <div className="HeaderCounter">
          <span className="TextLabel">{injectQuestionnaireVariables(description)}</span>
        </div>
      )}
    </div>
  );
}

export default QuestionnaireHeader;
