import { useSlider } from 'components/SliderProvider';
import React, { memo } from 'react';
import { isForm, isPage, isQuestion } from '../../utils';
import FormStep from './FormStep';
import Page from './Page';
import Question from './Question';
import StepLoader from './StepLoader';

const StepMemoized = memo(({ locale, leadData, inViewIndex, inView, forwardRef, step, submit, setLoader, settings }) => (
  <>
    {isQuestion(step) && <Question forwardRef={forwardRef} step={step} submit={submit} setLoader={setLoader} settings={settings} />}
    {isPage(step) && (
      <Page
        submit={submit}
        step={step}
        forwardRef={forwardRef} // reset the reference to null on static pages
      />
    )}
    {isForm(step) && (
      <FormStep
        locale={locale}
        leadData={leadData}
        inViewIndex={inViewIndex}
        settings={settings}
        submit={submit}
        inView={inView}
        forwardRef={forwardRef}
        step={step}
      />
    )}
  </>
));

function Step({ idx, step, forwardRef }) {
  const {
    submit,
    setLoader,
    settings,
    index,
    loader,
    formRef,
    inViewIndex,
    visibleTransitionIndexes,
    loaderVisible,
    transition,
    locale,
    leadData,
    submitLoader,
  } = useSlider();
  const inView = inViewIndex === idx;
  const isStepForReference = idx === index;
  const classes = `${step.classes || ''}${transition && visibleTransitionIndexes.indexOf(idx) !== -1 ? ' visible' : ''} ${
    inView ? 'active' : ''
  }`;

  return (
    <div ref={forwardRef} className={`Step ${classes}`}>
      {loader && inView && <StepLoader step={loader} loaderVisible={loaderVisible} submit={submitLoader} />}
      <div className="Container" style={{ display: loaderVisible && inView ? 'none' : 'block' }}>
        <StepMemoized
          locale={locale}
          leadData={leadData}
          inViewIndex={inViewIndex}
          submit={submit}
          setLoader={setLoader}
          settings={settings}
          inView={inView}
          forwardRef={isStepForReference ? formRef : null}
          step={step}
        />
      </div>
    </div>
  );
}

export default Step;
