import React from 'react';

function Button({ id, dataQa = '', type = 'button', className, onClick, tabIndex, children }) {
  return (
    <button
      id={id}
      tabIndex={tabIndex}
      className={`Button ${className}`}
      onClick={onClick}
      data-qa={dataQa}
      type={type === 'button' ? 'button' : 'submit'}
    >
      <span>{children}</span>
    </button>
  );
}

export default Button;
