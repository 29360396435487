import { useSlider } from 'components/SliderProvider';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import Checkbox from './Checkbox';
import Input from './Input';
import PostalCodeInput from './PostalCodeInput';
import RadioGroup from './RadioGroup';
import Textarea from './Textarea';

function getAutoCompleteValue(salesforceField) {
  return (
    {
      Alternative_phone__c: 'tel',
      Email: 'email',
      LastName: 'name',
      MobilePhone: 'tel',
      Phone: 'tel',
      PostalCode: 'postal-code',
    }[salesforceField] || 'on'
  );
}

function Field(props) {
  const { checked, forwardedRef, locale, name, salesforce, title, type, uuid } = props;
  const { register, control, errors, clearErrors, setValue } = useFormContext();
  const country = locale.slice(3, 5);
  const isIndia = country === 'IN';

  const { settings } = useSlider();

  const autoComplete = settings.autoComplete ? getAutoCompleteValue(salesforce) : 'off';

  // for old PLZ fields keep as it was before
  if (name === 'PostalCode') {
    return (
      <PostalCodeInput ref={forwardedRef} autoComplete={autoComplete} {...props} type={type === 'postalcode' || isIndia ? 'text' : type} />
    );
  }

  const onRadioChange = (inputName, value) => {
    setValue(inputName, value);
  };

  const fields = {
    age: <Input autoComplete={autoComplete} ref={forwardedRef} {...props} max={99} min={1} pattern="[0-9]*" type="number" />,
    checkbox: (
      <Controller
        control={control}
        name={salesforce}
        defaultValue={checked}
        render={(renderProps) => (
          <Checkbox
            {...renderProps}
            className="Checkbox form-group"
            hasError={!!errors[name]}
            id={uuid}
            onChange={(event) => {
              clearErrors(salesforce);
              renderProps.onChange(event.target.checked);
            }}
            checked={renderProps.value}
            title={title}
          />
        )}
      />
    ),
    email: <Input ref={forwardedRef} autoComplete={autoComplete} {...props} />,
    number: <Input ref={forwardedRef} autoComplete={autoComplete} {...props} type="number" min={0} pattern="[0-9]*" />,
    phone: <Input ref={forwardedRef} autoComplete={autoComplete} {...props} type="tel" />,
    radio: <RadioGroup className="Radio form-group" labelClassName="radio-inline" onChange={onRadioChange} ref={register} {...props} />,
    textarea: <Textarea className="form-control" autoComplete={autoComplete} {...props} />,
  };

  return fields[type.toLowerCase()] || <Input ref={forwardedRef} autoComplete={autoComplete} {...props} />;
}

export default Field;
