import { isForm, isPage } from '../../utils';

const formatIndex = (index) => {
  if (!index) return null;

  index = parseInt(index, 10);
  return `${index < 10 ? `0${index}` : index}`;
};

const DEFAULT_STEP = {
  id: null,
  question: null,
  questionSalesforce: null,
  questionTitle: null,
  type: null,
};

export default class DataLayer {
  constructor(options = {}) {
    this.items = [];
    this.id = 0;
    this.options = options;
  }

  static getFormFields(leadData, fields = []) {
    const value =
      fields
        .map((item) => item.salesforce)
        .map((key) => leadData[key])
        .filter((fieldValue) => !!fieldValue)
        .join(', ') || null;

    return {
      answer: value,
      answerTitle: null,
    };
  }

  static getQuestionAnswers(answers = []) {
    const answer =
      answers
        .filter((item) => item.checked)
        .slice(0, 1)
        .pop() || {};

    return {
      answer: answer.salesforce || null,
      answerTitle: answer.title || null,
    };
  }

  static getAnswer(step, leadData) {
    if (!step || isPage(step)) return {};
    if (isForm(step)) return DataLayer.getFormFields(leadData, step.fields);

    return DataLayer.getQuestionAnswers(step.answers);
  }

  getPrevStep(step, leadData) {
    const prevItem = this.items[this.items.length - 1];
    const prevStep = prevItem ? prevItem.stepView : DEFAULT_STEP;

    const answer = DataLayer.getAnswer(step, leadData);

    return {
      ...prevStep,
      id: prevStep.id || null,
      ...answer,
    };
  }

  /**
   * To identify the unique salesforce value for current step
   * - for questions: "salesforce" field
   * - for forms: "salesforce" field of the first field
   */
  static getQuestionSalesforce(step = {}) {
    if (!step) return null;

    const { fields = [], salesforce = null } = step;

    if (isForm(step)) {
      const firstField = fields.slice(0, 1).pop();
      return (firstField && firstField.salesforce) || null;
    }

    return salesforce;
  }

  getCurrentStepView(step) {
    const prevStep = this.items[this.items.length - 1];
    const { label, type, title } = step;

    return {
      id: formatIndex(parseInt((prevStep && prevStep.stepView.id) || 0, 10) + 1),
      question: label,
      questionSalesforce: DataLayer.getQuestionSalesforce(step),
      questionTitle: title,
      type,
    };
  }

  addLoader({ loader }) {
    const { uuid, label } = this.options;

    const details = {
      label,
      loader: {
        actionText: loader.action_text,
        label: loader.label,
        timeout: loader.autojump_timeout,
        uuid: loader.uuid,
      },
    };

    window.sessionLayer.gtm.push({
      details,
      event: 'questionnaireLoaderEvent',
      uuid,
    });
  }

  add({ leadData, prevStep, step, userAction }) {
    const { uuid, label } = this.options;
    const previousStep = this.getPrevStep(prevStep, leadData);
    const questionnaire = {
      data: DataLayer.getData(prevStep, leadData),
      label,
      step: { ...previousStep, userAction },
      stepView: this.getCurrentStepView(step),
      typeOfTreatment: DataLayer.getTypeOfTreatment(),
    };

    this.items.push(questionnaire);

    window.sessionLayer.gtm.push({
      event: 'questionnaireEvent',
      questionnaire,
      uuid,
    });
  }

  static getGender(Salutation) {
    if (!Salutation) return null;

    const isMr = Salutation === 'Mr.';

    return isMr ? 'male' : 'female';
  }

  static getData(step, leadData) {
    const { Email = '', Phone = '', PostalCode = '', Precise_Age__c = '', LastName = '', Salutation = '' } = leadData;

    let data = {};

    if (step && isForm(step)) {
      data = {
        Email: (Email || '').toLowerCase(),
        Gender: this.getGender(Salutation),
        LastName,
        Phone,
        PostalCode,
        Precise_Age__c,
        Salutation,
      };
    }

    return {
      leadCustomerGID: window.sessionLayer.session.getGid(),
      ...data,
    };
  }

  static getTypeOfTreatment() {
    const { Type_of_treatment__c = null } = window.sessionLayer.leadHandler.lead.getData() || {};
    return Type_of_treatment__c;
  }
}
