import { useCallback, useState } from 'react';

export default function useDom(fn) {
  const [value, setValue] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setValue(fn(node));
    }
  });

  return [value, ref];
}
