import React from 'react';
import injectQuestionnaireVariables from '../../utils/injectQuestionnaireVariables';

function Tooltip({ tooltip }) {
  return (
    <span className="Tooltip">
      <i className="icon fa-info-circle" />
      <span className="TextLabel">{injectQuestionnaireVariables(tooltip)}</span>
    </span>
  );
}

export default Tooltip;
