import bn_IN from './bn_IN';
import de_CH from './de_CH';
import de_DE from './de_DE';
import en_GB from './en_GB';
import en_IN from './en_IN';
import en_MY from './en_MY';
import en_US from './en_US';
import en_ZA from './en_ZA';
import es_US from './es_US';
import fr_CH from './fr_CH';
import fr_FR from './fr_FR';
import fr_LU from './fr_LU';
import gu_IN from './gu_IN';
import hi_IN from './hi_IN';
import kn_IN from './kn_IN';
import ko_KR from './ko_KR';
import ml_IN from './ml_IN';
import ms_MY from './ms_MY';
import mr_IN from './mr_IN';
import nl_NL from './nl_NL';
import pa_IN from './pa_IN';
import ta_IN from './ta_IN';
import te_IN from './te_IN';
import th_TH from './th_TH';
import zh_HK from './zh_HK';
import zh_MY from './zh_MY';
import zh_TW from './zh_TW';

const SUPPORTED_LOCALES = {
  bn_IN,
  de_CH,
  de_DE,
  en_GB,
  en_IN,
  en_MY,
  en_US,
  en_ZA,
  es_US,
  fr_CH,
  fr_FR,
  fr_LU,
  gu_IN,
  hi_IN,
  kn_IN,
  ko_KR,
  ml_IN,
  mr_IN,
  ms_MY,
  nl_NL,
  pa_IN,
  ta_IN,
  te_IN,
  th_TH,
  zh_HK,
  zh_MY,
  zh_TW,
};

const DEFAULT_LOCALE = en_GB;

const i18n = (locale) => SUPPORTED_LOCALES[locale] || DEFAULT_LOCALE;

export default i18n;
