import React from 'react';
import { useFormContext } from 'react-hook-form';

function Textarea(props, ref) {
  const { placeholder, rows = 2, name, className } = props;
  const { register } = useFormContext();

  return (
    <textarea
      ref={(e) => {
        register(e);
        if (ref) {
          ref.current = e;
        }
      }}
      className={`Textarea ${className}`}
      name={name}
      placeholder={placeholder}
      rows={rows}
    />
  );
}

export default React.forwardRef(Textarea);
