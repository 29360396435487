import fetchQuestionnaire from 'api';
import ErrorNode from 'components/ErrorNote';
import Questionnaire from 'components/Questionnaire';
import QuestionnaireHeader from 'components/QuestionnaireHeader';
import SliderProvider from 'components/SliderProvider';
import Spinner from 'components/Spinner';
import tracker from 'components/Tracker';
import { getDeviceClassName, useAsync, useDom } from 'hooks';
import React, { useEffect, useState } from 'react';
import i18n from 'translations/translations';
import { getResourcePath, getUrlParameter, normalizeBooleans } from 'utils';
import statusTypes from './statusTypes';

function Application({ uuid, title, onDataLoaded, settings }) {
  const [step, setStep] = useState({});
  const { headTitle, headSubtitle, classes = '' } = step;
  const [deviceClassName, ref] = useDom(getDeviceClassName);
  const normalizedSettings = normalizeBooleans(settings);
  const { useFsCdn, isSameDomain } = normalizedSettings;
  const [status, setStatus] = useState(statusTypes.idle);

  const baseUrl = useFsCdn ? getResourcePath('questionnaires', isSameDomain) : getResourcePath('questionnairesNoCdn', isSameDomain);

  const process = React.useCallback(
    (questionnaireData) => {
      const utmMedium = getUrlParameter('utm_medium');
      const medium = getUrlParameter('medium');
      const isSpecialDescription = utmMedium === 'social' || medium === 'social';

      // set special description when used with traffic from social networks
      questionnaireData.description = isSpecialDescription
        ? i18n(questionnaireData.locale).smCounterText
        : questionnaireData.description || '';

      // final settings = questionnaire settings from DB and script tags
      // that overwrite these (higher priority)
      questionnaireData.settings = {
        ...normalizeBooleans(questionnaireData.settings),
        ...normalizedSettings,
      };
      const { offerType } = questionnaireData.settings;
      questionnaireData.formUniqueIdentifier = `${uuid}${offerType ? `-${offerType}` : ''}`;

      questionnaireData.title = title || questionnaireData.title;

      // uuid: "f549ffd1-971c-4370-9fed-421bcdfc98c0"
      // questionnaireData.steps = questionnaireData.steps.slice(7, 9);

      // salutation workaround
      // TODO: WS-3256
      if (!questionnaireData.steps) return {};

      questionnaireData.steps = questionnaireData.steps.map((item) => {
        if (item.fields) {
          item.fields = item.fields.map((field) => {
            if (field.type === 'radio') {
              field.options = field.value;
            }
            return field;
          });
        }

        return item;
      });

      return questionnaireData;
    },
    [normalizedSettings, title, uuid]
  );

  const callback = React.useCallback(async () => {
    try {
      const questionnaireData = await fetchQuestionnaire(baseUrl, uuid);
      tracker.track('FormServiceDataFetched');
      setStep(questionnaireData.steps[0]);
      // return process(mockQuestionnaireData);
      return process(questionnaireData);
    } catch (e) {
      // TODO: Error handling
      // console.log(e);
    }
  }, [baseUrl, process, uuid]);

  const { data, status: responseStatus, error } = useAsync(callback);

  const { title: titleFromData = '', settings: settingsFromData = {}, description } = data || {};

  useEffect(() => {
    if (data) {
      if (onDataLoaded) {
        onDataLoaded(data);
      }
    }
  }, [data, onDataLoaded]);

  const { theme = '' } = settingsFromData;

  const onViewIndexChange = React.useCallback(
    (inViewIndex) => {
      const activeStep = data.steps[inViewIndex];
      setStep(activeStep);
    },
    [data]
  );

  return (
    <div ref={ref} className={`Application ${theme ? `theme-${theme}` : ''} ${deviceClassName}`}>
      {responseStatus === 'error' && <ErrorNode error={error} />}
      {(responseStatus === 'loading' || status === statusTypes.idle) && <Spinner />}
      {data && (
        <>
          {status === statusTypes.ready && (
            <QuestionnaireHeader
              stepClasses={classes}
              title={headTitle || titleFromData}
              isSameDomain={isSameDomain}
              description={headSubtitle || description}
            />
          )}
          <SliderProvider
            setStatus={setStatus}
            status={status}
            data={data}
            onViewIndexChange={onViewIndexChange}
            uuid={uuid}
            title={title}
            onDataLoaded={onDataLoaded || null}
            settings={settings}
          >
            {status === statusTypes.ready && <Questionnaire steps={data.steps} settings={data.settings} {...data} />}
          </SliderProvider>
        </>
      )}
    </div>
  );
}

export default Application;
