import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { isEmptyString } from 'utils';
import Tooltip from '../Tooltip';
import SafeHtml from '../Utils';
import injectQuestionnaireVariables from '../../utils/injectQuestionnaireVariables';

function StepHeader({ errors = {}, step: { subtitle, title, tooltip }, name = '' }) {
  const hasError = !!errors[name];

  const noStringToDisplay = [title, tooltip, subtitle].every(isEmptyString);

  if (noStringToDisplay) return null;

  return (
    <div className="StepHeader">
      <div className="TextLabel StepTitle">
        <SafeHtml html={injectQuestionnaireVariables(title)} style={{ display: 'inline-block' }} />

        {tooltip && <Tooltip tooltip={tooltip} />}
      </div>
      {!hasError ? (
        <span className="TextLabel StepSubtitle">{injectQuestionnaireVariables(subtitle)}</span>
      ) : (
        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => <span className="TextLabel StepSubtitle highlight">{injectQuestionnaireVariables(message)}</span>}
        />
      )}
    </div>
  );
}

export default StepHeader;
