import USER_ACTIONS from 'constants/userActions';
import setQuestionnaireVariables from 'utils/setQuestionnaireVariables';
import DataHandler from 'components/DataHandler';
import tracker from 'components/Tracker';
import { FORMCLIENT_FORM_RENDERED, FORMCLIENT_FORM_STEP_CHANGED, FORMCLIENT_FORM_SUBMITTED } from 'components/Tracker/eventNames';
import { fireExternalEvent, isForm, isQuestion, pushLeadScoreToDataLayer, triggerEvent, wait } from 'utils';
import getNext from 'actions/getNext';
import { getLoaderDelay } from '../components/SliderProvider/loaders';

function createDataHandler(data) {
  const { uuid, label, steps } = data;
  const dataHandler = new DataHandler({ label, uuid });

  dataHandler.add({
    leadData: {
      label,
      prevStep: null,
      step: steps[0],
      uuid,
    },
    prevStep: null,
    step: data.steps[0],
    userAction: USER_ACTIONS.FIRST_STEP,
  });

  return dataHandler;
}

export const ACTION_NAMES = {
  GO_BACK: 'GO_BACK',
  INIT: 'INIT',
  PUSH_TO_DATA_HANDLER: 'PUSH_TO_DATA_HANDLER',
  SKIP_QUESTION: 'SKIP_QUESTION',
  SUBMIT: 'SUBMIT',
  SUBMIT_LOADER: 'SUBMIT_LOADER',
  SUBMIT_SUCCESS: 'SUBMIT_SUCCESS',
};

function markAnswersSelected(answers, answerValue) {
  const preAnsweredStepAnswer = answers.find((item) => item.salesforce === answerValue);
  if (!preAnsweredStepAnswer) return;
  preAnsweredStepAnswer.checked = true;
}

async function onLastStepReached(formUniqueIdentifier) {
  const {
    sessionLayer: { leadHandler },
  } = window;
  const pushOnCompletion = leadHandler.lead.get('pushOnCompletion');

  if (pushOnCompletion) {
    await leadHandler.pushWithoutDelay(formUniqueIdentifier);
  }

  return wait(2000);
}

export function createActions(data) {
  const dataHandler = createDataHandler(data);
  let loaderDelayTimer = null;

  return {
    [ACTION_NAMES.INIT]:
      ({ getState }) =>
      () => {
        const { index, steps } = getState();
        const { label, required, type, uuid } = steps[index];

        tracker.log(FORMCLIENT_FORM_RENDERED, {
          form: {
            currentStep: { label, required, type, uuid },
            totalSteps: steps.length,
            uuid: data.uuid,
          },
        });

        tracker.track('FormClientRendered');
        triggerEvent(window, 'FormClientRendered');
      },
    [ACTION_NAMES.PUSH_TO_DATA_HANDLER]:
      ({ getState }) =>
      () => {
        const { prevIndex, index, leadData, steps, userAction } = getState();

        dataHandler.add({
          leadData,
          prevStep: steps[prevIndex],
          // active step
          step: steps[index],
          userAction: userAction || USER_ACTIONS.MANUAL,
        });
      },
    /**
     * Mark answer object for a pre-answered Step as selected
     * @param bufferAnswer
     */
    [ACTION_NAMES.SKIP_QUESTION]:
      ({ dispatch, getState }) =>
      ({ answer }) => {
        const { steps, index, locale } = getState();
        const answerKey = Object.keys(answer).pop();
        const answerValue = answer[answerKey];
        const stepsToSkip = steps.filter((step) => {
          if (isQuestion(step)) {
            return step.salesforce === answerKey;
          }
          if (isForm(step)) {
            return step.fields && step.fields.length === 1 && step.fields[0].salesforce === answerKey;
          }
          return false;
        });

        if (stepsToSkip.length === 0) return;

        // marking as "skip" leads to ignoring the question during getting the next index
        stepsToSkip.forEach((step) => {
          step.skip = true;
          const answers = isForm(step) ? step.fields : step.answers;
          markAnswersSelected(answers, answerValue);
        });

        const isCurrentStepAnswered = !!steps[index].skip;
        if (isCurrentStepAnswered) {
          const [nextIndex] = getNext(index, locale, steps);
          dispatch({ nextIndex, type: 'SKIP' });
        }
      },
    [ACTION_NAMES.SUBMIT]:
      ({ dispatch, getState }) =>
      async ({ values }) => {
        const { steps = [], loader, leadData, prevIndex, index, locale, formUniqueIdentifier, loaderVisible, loaders } = getState();

        if (loaderVisible) return;

        const currentStep = steps[index];

        tracker.log(FORMCLIENT_FORM_SUBMITTED, {
          data: values,
          form: {
            currentStep,
          },
        });

        // TODO: Combine the 2 events "submit" & "nextEvent" into one
        // Requires updating all the instances in the LP repo & possibly SEO website
        triggerEvent('#formclient', 'submit', {
          detail: { formUniqueIdentifier, index, leadData: { ...leadData, ...values }, prevIndex },
        });

        const [nextIndex, nextLoader] = getNext(index, locale, steps);

        triggerEvent('#formclient', 'nextEvent', {
          detail: { formUniqueIdentifier, index: nextIndex, prevIndex: index },
        });

        dispatch({ type: 'SAVE_VALUES', values }); // must come after anything that might update the buffer

        window.sessionLayer.leadHandler.push(formUniqueIdentifier, values, { silent: true });
        setQuestionnaireVariables({ step: currentStep, values });

        let loaderToShow = loader;

        if (nextLoader) {
          loaderToShow = loaders.find((item) => item.uuid === nextLoader);
          dispatch({ loader: loaderToShow, type: 'SET_LOADER' });
        }

        if (loaderToShow) {
          dispatch({ type: 'SHOW_LOADER' });

          dataHandler.addLoader({
            loader: loaderToShow,
          });

          const delay = getLoaderDelay(loaderToShow);

          if (delay && delay > 0) {
            loaderDelayTimer = setTimeout(() => dispatch({ nextIndex, type: 'GO_TO' }), delay);
          }
        } else {
          dispatch({ nextIndex, type: 'GO_TO' });
        }

        fireExternalEvent(currentStep.label); // trigger event which one can use from the outside

        if (locale === 'en_US') {
          pushLeadScoreToDataLayer(formUniqueIdentifier, leadData);
        }

        const indexMaximum = steps.length - 1;

        if (nextIndex === indexMaximum) {
          await onLastStepReached(formUniqueIdentifier);
          triggerEvent('#SessionLayer', 'lastStepQuestionnaire');
        }
      },
    [ACTION_NAMES.SUBMIT_LOADER]:
      ({ dispatch, getState }) =>
      () => {
        const { steps = [], index, locale } = getState();
        const [nextIndex] = getNext(index, locale, steps);

        clearTimeout(loaderDelayTimer);

        dispatch({ nextIndex, type: 'GO_TO' });
      },
    [ACTION_NAMES.GO_BACK]:
      ({ dispatch, getState }) =>
      () => {
        const { prevIndex } = getState();
        dispatch({ prevIndex, type: 'GO_TO_PREVIOUS' });
      },
    [ACTION_NAMES.SUBMIT_SUCCESS]:
      ({ dispatch, getState }) =>
      ({ callback }) => {
        const { index, steps, prevIndex, formUniqueIdentifier, leadData } = getState();
        const { label, required, type, uuid } = steps[index];

        tracker.log(FORMCLIENT_FORM_STEP_CHANGED, {
          form: {
            currentStep: { label, required, type, uuid },
            index,
            prevIndex,
            totalSteps: steps.length,
            uuid: data.uuid,
          },
        });

        // emulate on transition end event when animation is disabled
        dispatch({ index, type: 'AFTER_STEP_CHANGED' });

        triggerEvent('#formclient', 'submit_succeed', {
          detail: { formUniqueIdentifier, index, leadData, prevIndex },
        });

        if (callback) {
          callback(index);
        }
      },
  };
}
