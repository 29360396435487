export const FORMCLIENT_EMAIL_CONSENT_FETCH_FAILED = 'FORMCLIENT_EMAIL_CONSENT_FETCH_FAILED';
export const FORMCLIENT_EMAIL_CONSENT_FOUND = 'FORMCLIENT_EMAIL_CONSENT_FOUND';
export const FORMCLIENT_EMAIL_CONSENT_NOTFOUND = 'FORMCLIENT_EMAIL_CONSENT_NOTFOUND';

export const FORMCLIENT_FORM_RENDERED = 'FORMCLIENT_FORM_RENDERED';
export const FORMCLIENT_FORM_STEP_CHANGED = 'FORMCLIENT_FORM_STEP_CHANGED';
export const FORMCLIENT_FORM_SUBMITTED = 'FORMCLIENT_FORM_SUBMITTED';
export const FORMCLIENT_FORM_TEMPLATING_ERROR = 'FORMCLIENT_FORM_TEMPLATING_ERROR';

export const FORMCLIENT_PHONENUMBER_LIBRARY_NOT_AVAILABLE = 'FORMCLIENT_PHONENUMBER_LIBRARY_NOT_AVAILABLE';
export const FORMCLIENT_PHONENUMBER_INVALID_FORMAT = 'FORMCLIENT_PHONENUMBER_INVALID_FORMAT';
export const FORMCLIENT_PHONENUMBER_VALIDATION_APPROVED = 'FORMCLIENT_PHONENUMBER_VALIDATION_APPROVED';
export const FORMCLIENT_PHONENUMBER_VALIDATION_REJECTED = 'FORMCLIENT_PHONENUMBER_VALIDATION_REJECTED';

export const FORMCLIENT_PHONE_CONSENT_FETCH_FAILED = 'FORMCLIENT_PHONE_CONSENT_FETCH_FAILED';
