import * as yup from 'yup';
import './validation';

const validationTypes = [
  {
    inputType: ['email', 'phone', 'text', 'tel', 'url', 'radio', 'postalcode'],
    validationType: 'string',
  },
  { inputType: ['checkbox'], validationType: 'boolean' },
  {
    inputType: ['number', 'age'],
    validationType: 'number',
  },
];

// helper for yup transform function
function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

function getYupValidator(type) {
  const validation = validationTypes.find((item) => item.inputType.includes(type));

  if (validation) {
    return yup[validation.validationType];
  }

  // TODO: Refactor answers so they have a type: "checkbox"/"radio"
  // answers don't have types. We can detect them by "checked" property
  return undefined;
}

function shouldValidate(item) {
  return !!item.validation_text;
}

function getValidationRules(field, options = {}) {
  const { locale, remoteEmailValidation } = options;
  const validations = [];

  // TODO: move rules to the validationTypes array
  if (field.required) {
    validations.push({
      params: [field.required_text],
      type: 'required',
    });

    if (field.type.toLowerCase() === 'checkbox') {
      validations.push({ params: [[true], field.required_text], type: 'oneOf' });
    }
  }

  if (!shouldValidate(field)) return validations;

  if (field.type === 'age') {
    validations.push(
      {
        params: [1, field.validation_text],
        type: 'min',
      },
      {
        params: [99, field.validation_text],
        type: 'max',
      },
      { params: [field.validation_text], type: 'integer' },
      { params: [field.validation_text], type: 'positive' }
    );
  }

  if (field.salesforce === 'PostalCode') {
    const country = locale.slice(3, 5);
    const isCanada = country === 'CA';
    const isIndia = country === 'IN';
    const isUS = country === 'US';
    const isFrance = country === 'FR';
    const isSwitzerland = country === 'CH';
    const isNetherlands = country === 'NL';

    if (field.type.toLowerCase() === 'postalcode' && isCanada) {
      validations.push({
        params: [field.validation_text],
        type: 'postalCodeRuleCanada',
      });
    } else if (isIndia) {
      validations.push({
        params: [locale, field.validation_text],
        type: 'postalCodeRuleIndia',
      });
    } else if (isUS) {
      validations.push(
        {
          params: [5, field.validation_text],
          type: 'min',
        },
        {
          params: [5, field.validation_text],
          type: 'max',
        }
      );
    } else if (isFrance) {
      validations.push({
        params: ['^(?!00|99)[0-9]{5}$', field.validation_text], // https://regex101.com/r/iZ4nZT/1
        type: 'matches',
      });
    } else if (isSwitzerland) {
      validations.push({
        params: [field.validation_text],
        type: 'postalCodeRuleSwitzerland',
      });
    } else if (isNetherlands) {
      validations.push({
        params: [field.validation_text],
        type: 'postalCodeRuleNetherlands',
      });
    }
  }

  if (field.type === 'phone') {
    validations.push({ params: [locale, field.validation_text], type: 'phoneRule' });
  }

  if (field.type === 'email') {
    validations.push({
      params: [remoteEmailValidation, field.validation_text, locale],
      type: 'emailRule',
    });
  }

  return validations;
}

function createSchema(currentSchema, config, options = {}) {
  const { salesforce, type } = config;

  // HACK:  replace type "number" with type "string" in FormService and FBD for India
  const yupValidator = salesforce === 'PostalCode' ? yup.string : getYupValidator(type);

  if (!yupValidator) {
    return currentSchema;
  }

  let validator = yupValidator().transform(emptyStringToNull).nullable();
  const validations = getValidationRules(config, options);

  validations.forEach((validation) => {
    const { params, type: validationType } = validation;
    if (!validator[validationType]) {
      return;
    }
    validator = validator[validationType](...params);
  });
  const schema = currentSchema;
  schema[`${salesforce}`] = validator;
  return schema;
}

export default createSchema;
