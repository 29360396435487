const appConfig = {
  counter: {
    interval: 30000,
  },
  localeDomainMap: {
    de_CH: 'https://www.audibene.ch/',
    de_DE: 'https://www.audibene.de/',
    en_CA: 'https://www.hear.com/ca/',
    en_GB: 'https://www.hear.com/uk/',
    en_IN: 'https://www.hear.com/in/',
    en_MY: 'https://www.hear.com/my/',
    en_TH: 'https://www.hear.com/th/',
    en_US: 'https://www.hear.com/',
    en_ZA: 'https://www.hear.com/za/',
    fr_CH: 'https://www.audibene.ch/fr/',
    fr_FR: 'https://www.audibene.fr/',
    hi_IN: 'https://www.soundrise.com/hi/',
    ja_JP: 'https://www.audibene.jp/',
    ko_KR: 'https://www.hear.com/kr/',
    ms_MY: 'https://www.hear.com/my/',
    nl_NL: 'https://www.audibene.nl/',
    th_TH: 'https://www.hear.com/th/',
    zh_MY: 'https://www.hear.com/my/',
  },
  postalCodeTimeout: 5000,

  resources: {
    absolute: {
      basePath: 'https://formclient.cdn.audibene.net/v2/',
      counter: 'https://marketing.audibene.net/counter',
      emailConsent: 'https://customer.hear.com/profile/email-with-consent',
      questionnaires: 'https://formservice.cdn.audibene.net/questionnaires/',
      questionnairesNoCdn: 'https://formservice.audibene.net/questionnaires/',
      trackerService: 'https://et.cdn.audibene.net/services/collector/event',
      validationService: 'https://validation.audibene.net/validation',
    },
    relative: {
      // relative paths are working only on static pages
      basePath: '/formclient/v2/',
      questionnaires: '/questionnaires/',
    },
  },

  validationDelay: 500,

  validationTimeout: 5000,

  view: {
    enablePaginationClick: false,
    maxVerticalFields: 3,
    mobileBreakPoint: 600,
    tabletBreakPoint: 768,
    tinymobileBreakPoint: 320,
  },
};

appConfig.resources.relative = {
  ...appConfig.resources.absolute,
  ...appConfig.resources.relative,
};

export default appConfig;
