import { ErrorMessage } from '@hookform/error-message';
import React from 'react';
import { useFormContext } from 'react-hook-form';

function ErrorMessageComponent({ name }) {
  const { errors = {} } = useFormContext();

  return (
    <ErrorMessage
      errors={errors}
      name={name}
      render={({ message }) => (
        <>
          <span className="TextLabel Validity">{message}</span>
          <i className="icon fa-exclamation" />
        </>
      )}
    />
  );
}

export default ErrorMessageComponent;
