const fetchQuestionnaire = (url, uuid) =>
  fetch(`${url}${uuid}`)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status === 404) {
        throw new Error('Invalid UUID');
      } else {
        return response.json();
      }
    })
    .catch((error) => {
      // TODO: Do we need this console?
      // console.error(`[FormClient] Bad Request ${error.message}`);
      throw new Error(`Bad Request (${error.message})`);
    });

export default fetchQuestionnaire;
