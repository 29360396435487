import { yupResolver } from '@hookform/resolvers/yup';
import Field from 'components/Form/Field';
import createSchema from 'components/Form/schema';
import PrivacyText from 'components/PrivacyText';
import { useSlider } from 'components/SliderProvider';
import StepHeader from 'components/StepHeader';
import SafeHtml from 'components/Utils';
import React, { memo, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { wait } from 'utils';
import * as yup from 'yup';
import injectQuestionnaireVariables from '../../../utils/injectQuestionnaireVariables';

const { userAgent } = window.navigator;
const isInternetExplorer = userAgent.indexOf('MSIE ') > -1 || userAgent.indexOf('Trident') > -1;

const shouldFocus = ({ type }) => type !== 'radio' && type !== 'checkbox';

const hasField = (name, fields = []) => fields.find(({ salesforce }) => salesforce.toLowerCase() === name.toLowerCase());

const excludeFromFields = (fields, excludedField) => fields.filter((item) => item.salesforce.toLowerCase() !== excludedField.toLowerCase());

function FormStep({ forwardRef, inView, inViewIndex, leadData, locale, settings, step, submit }) {
  const inputRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const isEmailAnswered = !!leadData.Email;
  const fields = isEmailAnswered ? excludeFromFields(step.fields, 'Email') : step.fields || [];
  const { isSameDomain, remoteEmailValidation, theme } = settings;
  const schema = fields.reduce((obj, field) => createSchema(obj, field, { locale, remoteEmailValidation }), {});
  const FormSchema = yup.object().shape(schema);
  const { prevIndex } = useSlider();

  const methods = useForm({
    defaultValues: fields,
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: (...args) =>
      // IE11 bug. When the input is in focus, and the next button is pressed
      // the resolver doesn't return an error. This happens only when  "shouldFocusError" is set to true
      // Seems to be related to focus handling
      new Promise((resolve) => {
        setTimeout(() => {
          resolve(yupResolver(FormSchema)(...args));
        }, 0);
      }),
    shouldFocusError: inView,
  });

  const {
    errors,
    formState: { isSubmitSuccessful, isSubmitted, isSubmitting },
    getValues,
    handleSubmit,
    reset,
  } = methods;

  const { action_text, privacy_text, description } = step;
  const focusIndex = fields.findIndex(shouldFocus);

  useEffect(() => {
    if (inputRef.current && !isInternetExplorer) {
      inputRef.current.focus();
    }
  }, [inViewIndex]);

  async function onSubmit(values) {
    setValidated(true);

    if (isSubmitting || (isSubmitted && isSubmitSuccessful)) {
      return Promise.resolve();
    }

    await wait(450); // Artificial delay
    submit(values);
  }

  function onError() {
    setValidated(true);
  }

  useEffect(() => {
    if (inView && inViewIndex < prevIndex) {
      reset(getValues());
    }
  }, [getValues, inView, inViewIndex, prevIndex, reset]);

  return (
    <FormProvider {...methods}>
      <StepHeader step={step} errors={errors} />
      <form
        className="Form"
        onSubmit={(event) => {
          event.preventDefault();
          setValidated(false);
          handleSubmit(onSubmit, onError)(event);
        }}
        ref={forwardRef}
        noValidate
        autoComplete="off"
      >
        {description && (
          <div className="TextLabel Description">
            <span>{injectQuestionnaireVariables(description)}</span>
          </div>
        )}
        <div className="Container FieldsContainer">
          {fields.map((item, index) => (
            <Field
              key={item.uuid}
              id={item.salesforce}
              validated={validated}
              forwardedRef={index === focusIndex && inView ? inputRef : null}
              name={item.salesforce}
              locale={locale}
              remoteEmailValidation={remoteEmailValidation}
              isSameDomain={isSameDomain}
              {...item}
              theme={theme}
            />
          ))}
          {action_text && (
            <button
              type="submit"
              data-qa="submit"
              className={`Button Action ${hasField('Email', fields) || fields.length > 1 ? 'Button-Big' : ''}`}
              onClick={() => {
                setValidated(false);
              }}
            >
              <SafeHtml html={injectQuestionnaireVariables(action_text)} />
            </button>
          )}
        </div>
        {privacy_text && <PrivacyText html={injectQuestionnaireVariables(privacy_text)} />}
      </form>
    </FormProvider>
  );
}

export default memo(FormStep);
