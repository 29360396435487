import Button from 'components/Button';
import React from 'react';
import i18n from 'translations/translations';
import { isForm, isPage } from '../../utils';
import { useSlider } from '../SliderProvider';

function shouldShowBackButton(showPreviousButton, backButton, step) {
  const doNotShowBackButton = !backButton || backButton === 'hide'; // the check for a falsy value is for retro-compatibility

  if (!showPreviousButton || doNotShowBackButton || isPage(step)) return false;

  const showBackButtonInQuestionsOnly = ['on-questions', 'v1'].includes(backButton); // "v1" & "on-questions" are an equivalent value, just for retro-compatibility

  if (isForm(step) && showBackButtonInQuestionsOnly) return false;

  return true;
}

const hasTextForSubmit = (step) => !!step.action_text;

function Footer({ forwardRef, children }) {
  const {
    loaderVisible,
    locale,
    previous,
    formRef,
    steps = [],
    index,
    showPreviousButton,
    settings: { autoJump, backButton },
  } = useSlider();

  if (loaderVisible) return null;

  const step = steps[index]; // the step that is sliding in
  const showNextButton = autoJump !== 'true-no-btn' && !hasTextForSubmit(step);
  const translations = i18n(locale);
  const showBackButton = shouldShowBackButton(showPreviousButton, backButton, step);

  return (
    <div ref={forwardRef} className="Container SliderFooter">
      <div className="Container Right">
        {showNextButton && (
          <Button
            type="submit"
            dataQa="button-next"
            tabIndex="0"
            className="Continue"
            onClick={() => {
              formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
            }}
          >
            {translations.next}
          </Button>
        )}
      </div>
      {showBackButton && (
        <div className="Container Left">
          <Button dataQa="button-prev" className="Previous" onClick={previous}>
            {translations.previous}
          </Button>
        </div>
      )}
      <div className={`Container ${backButton ? 'Center' : 'Left'}`}>{children}</div>
    </div>
  );
}

export default Footer;
