import React, { useState } from 'react';
import Input from '../Input';

const withPostalCode = (WrappedComponent) => {
  function PostalCodeInput({ forwardedRef, type, ...restProps }) {
    const [loading] = useState(false);

    return <WrappedComponent {...restProps} ref={forwardedRef} type={type} pattern={type === 'number' ? '[0-9]*' : ''} loading={loading} />;
  }

  return React.forwardRef((props, ref) => <PostalCodeInput {...props} forwardedRef={ref} />);
};

export default withPostalCode(Input);
