import Footer from 'components/Footer';
import Pagination from 'components/Pagination';
import { useSlider } from 'components/SliderProvider';
import React, { useEffect } from 'react';
import Step from '../Step';

function Questionnaire(props) {
  const {
    formUniqueIdentifier,
    settings: { disableMergeDelay, pushOnCompletion = false },
    steps,
  } = props;

  const { index, register, sliderHeights, stepContainer, footerRef } = useSlider();

  useEffect(() => {
    window.sessionLayer.leadHandler.push(formUniqueIdentifier, {
      disableMergeDelay,
      pushOnCompletion,
    });
  }, [disableMergeDelay, formUniqueIdentifier, pushOnCompletion]);

  return (
    <>
      <div
        ref={stepContainer}
        className="SliderViewer"
        style={{
          maxHeight: `${sliderHeights.maxHeight > 0 ? `${sliderHeights.maxHeight}px` : 'auto'}`,
          transform: `translateX(${index * -100}%)`,
        }}
      >
        {steps.map((step, idx) => (
          <Step key={step.uuid} forwardRef={register({ name: step.uuid })} idx={idx} step={step} />
        ))}
      </div>
      <Footer forwardRef={footerRef}>
        <Pagination />
      </Footer>
    </>
  );
}

export default Questionnaire;
