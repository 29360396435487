import ErrorMessage from 'components/Form/ErrorMessage';
import Label from 'components/Form/Label';
import SafeHtml from 'components/Utils';
import React from 'react';

function Checkbox(
  {
    id,
    hideError = false,
    labelId = '',
    hasError,
    title,
    className = '',
    inputClassName = '',
    onChange,
    disabled,
    labelClassName = 'checkbox-inline',
    name,
    value,
    ...rest
  },
  ref
) {
  return (
    <div className={`${className} ${hasError ? 'invalid' : ''}`}>
      <Label id={labelId} className={labelClassName}>
        <span className="InputContainer">
          <span className="Input">
            <input
              ref={ref}
              id={id}
              name={name}
              className={`${inputClassName}`}
              onChange={onChange}
              type="checkbox"
              disabled={disabled}
              value={value}
              {...rest}
            />
          </span>
        </span>
        <SafeHtml html={title} className="TextLabel" />
      </Label>
      {!hideError && <ErrorMessage name={name} />}
    </div>
  );
}

export default React.forwardRef(Checkbox);
