import QUESTIONNAIRE_VARIABLES from 'constants/questionnaireVariables';
import SALESFORCE_FIELDS from 'constants/salesforceFields';
import { isQuestion, isEqualArray, countryFromLocale, isForm } from 'utils';

function getNaturalNext(index, maxIndex) {
  return Math.min(index + 1, maxIndex);
}

function getStepIndex(steps, uuid) {
  const index = steps.findIndex((item) => item.uuid === uuid);
  const indexExists = index >= 0;

  return indexExists ? index : null;
}

function getStep(steps, uuid) {
  return steps.find((item) => item.uuid === uuid);
}

function getSelectedAnswer(step) {
  return step.answers.find((item) => item.checked);
}

function getSelectedAnswers(step) {
  return step.answers.filter((item) => item.checked);
}

function getSelectedAnswersUuid(step) {
  return getSelectedAnswers(step).map((item) => item.uuid);
}

function getNextFromSelectedAnswer(steps, step) {
  const { multi: multipleAnswersAllowed } = step;

  if (!isQuestion(step) || multipleAnswersAllowed) {
    return null;
  }

  const answer = getSelectedAnswer(step);

  if (!answer || !answer.next) {
    return null;
  }

  return getStepIndex(steps, answer.next);
}

function isStepToSkip(steps, currentStepIndex, nextStepIndex) {
  return steps[nextStepIndex].skip && currentStepIndex !== nextStepIndex;
}

function getQuestionnaireVariable(name) {
  return window.sessionLayer ? window.sessionLayer.questionnaireVariables.get(name) : '';
}

function questionnaireVariableMatches({ name, value }) {
  return getQuestionnaireVariable(name) === value;
}

function getFulfilledCondition(steps, conditions) {
  if (!conditions) {
    return [];
  }

  const conditionToFollow = conditions.find((condition) => {
    const { matches: conditionRules } = condition;
    const isQuestionnaireWithStillOnlyStepConditions = Array.isArray(conditionRules);

    if (isQuestionnaireWithStillOnlyStepConditions) {
      return conditionRules.every((rule) => {
        const questionStep = getStep(steps, rule.question);
        const selectedAnswers = getSelectedAnswersUuid(questionStep);
        return isEqualArray(selectedAnswers, rule.answers);
      });
    }

    const { steps: stepsConditionRules, variables: variablesConditionRules } = conditionRules;

    const stepsConditionRulesMatch = stepsConditionRules.every((rule) => {
      const questionStep = getStep(steps, rule.question);
      const selectedAnswers = getSelectedAnswersUuid(questionStep);
      return isEqualArray(selectedAnswers, rule.answers);
    });

    const variablesConditionRulesMatch = variablesConditionRules.every(questionnaireVariableMatches);
    return stepsConditionRulesMatch && variablesConditionRulesMatch;
  });

  if (!conditionToFollow) {
    return [];
  }

  const nextIndex = steps.findIndex((item) => item.uuid === conditionToFollow.follow);
  const { loader } = conditionToFollow;

  return [nextIndex, loader];
}
function northAmericaNextHandler(loader, nextStepIndex, steps) {
  if (!isForm(steps[nextStepIndex])) {
    return [nextStepIndex, loader];
  }

  const nextStepHasEmailField = steps[nextStepIndex].fields.some((field) => field.salesforce === SALESFORCE_FIELDS.EMAIL);

  if (!nextStepHasEmailField) {
    return [nextStepIndex, loader];
  }

  const userHasEmailWithConsent = getQuestionnaireVariable(QUESTIONNAIRE_VARIABLES.USER_HAS_EMAIL_WITH_CONSENT) === 'Yes';

  if (!userHasEmailWithConsent) {
    return [nextStepIndex, loader];
  }

  return [nextStepIndex + 1, loader];
}

function DENextHandler(loader, nextStepIndex, steps) {
  if (!isQuestion(steps[nextStepIndex])) {
    return [nextStepIndex, loader];
  }

  const [nextStepIsSMSConsentQuestion, nextStepIsWhatsappConsentQuestion] = [
    SALESFORCE_FIELDS.EXPRESS_WRITTEN_CONSENT,
    SALESFORCE_FIELDS.WHATSAPP_CONSENT_M,
  ].map((field) => steps[nextStepIndex].salesforce === field);
  const [hasSMSConsent, hasWhatsappConsent] = [
    QUESTIONNAIRE_VARIABLES.USER_SMS_CONSENT_GIVEN,
    QUESTIONNAIRE_VARIABLES.USER_WHATSAPP_CONSENT_GIVEN,
  ].map((variable) => getQuestionnaireVariable(variable) === 'Yes');

  if ((nextStepIsSMSConsentQuestion && hasSMSConsent) || (nextStepIsWhatsappConsentQuestion && hasWhatsappConsent)) {
    return DENextHandler(loader, nextStepIndex + 1, steps);
  }

  return [nextStepIndex, loader];
}

function getNextWithCountrySpecificLogic(country, loader, nextStepIndex, steps) {
  const countryHandlers = {
    CA: northAmericaNextHandler,
    DE: DENextHandler,
    US: northAmericaNextHandler,
  };

  if (!countryHandlers[country]) {
    return [nextStepIndex, loader];
  }

  return countryHandlers[country](loader, nextStepIndex, steps);
}

function getNext(currentStepIndex, locale, steps = []) {
  const maxIndex = steps.length - 1;
  const step = steps[currentStepIndex];

  if (!step) {
    return [];
  }

  const { follow: nextStepUuid, conditions } = step;

  let nextStepIndex = getStepIndex(steps, nextStepUuid) || getNaturalNext(currentStepIndex, maxIndex);
  let loader = null;

  nextStepIndex = getNextFromSelectedAnswer(steps, step) || nextStepIndex;

  const [nextStepIndexFromCondition, loaderFromCondition] = getFulfilledCondition(steps, conditions);

  nextStepIndex = nextStepIndexFromCondition || nextStepIndex;
  loader = loaderFromCondition || loader;

  if (isStepToSkip(steps, currentStepIndex, nextStepIndex)) {
    [nextStepIndex, loader] = getNext(nextStepIndex, locale, steps);
  }

  const country = countryFromLocale(locale);

  return getNextWithCountrySpecificLogic(country, loader, nextStepIndex, steps);
}

export default getNext;
