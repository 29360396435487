const IS_DEV = process.env.NODE_ENV === 'development';
const IS_CYPRESS = process.env.CYPRESS;

export function getLoaderByStep(loaders, step) {
  const loaderUUID = step.loader;

  return loaders.find((item) => item.uuid === loaderUUID);
}

export function getLoaderDelay(loader) {
  const { autojump_timeout: autojumpTimeout } = loader;
  const delay = autojumpTimeout * 1000;

  return delay && IS_DEV && !IS_CYPRESS ? 250 : delay;
}
