import { Liquid } from 'liquidjs';
import tracker from '../components/Tracker';
import { FORMCLIENT_FORM_TEMPLATING_ERROR } from '../components/Tracker/eventNames';

const engine = new Liquid();

function injectQuestionnaireVariables(template) {
  if (!template) {
    return template;
  }
  const { questionnaireVariables } = window.sessionLayer;
  if (!questionnaireVariables) {
    return engine.parseAndRenderSync(template);
  }
  try {
    const variables = questionnaireVariables.getAll();
    return engine.parseAndRenderSync(template, variables);
  } catch (e) {
    tracker.log(FORMCLIENT_FORM_TEMPLATING_ERROR, {
      error: e,
      template,
    });
    try {
      return engine.parseAndRenderSync(template);
    } catch (error) {
      tracker.log(FORMCLIENT_FORM_TEMPLATING_ERROR, {
        error,
        template,
      });
      return template;
    }
  }
}

export default injectQuestionnaireVariables;
