import htmr from 'htmr';
import React, { useMemo } from 'react';

const tagsToFilterOut = ['body', 'code', 'form', 'html', 'link', 'script'];

const options = {
  transform: tagsToFilterOut.reduce((result, tag) => ({ ...result, [tag]: () => null }), {}),
};

function SafeHtml({ children, html, className = '', style = {} }) {
  const memoizedHTML = useMemo(() => htmr(html, options), [html]);

  return (
    <span className={className} style={style}>
      {memoizedHTML}
      {children}
    </span>
  );
}

export default SafeHtml;
