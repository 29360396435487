import { yupResolver } from '@hookform/resolvers/yup';
import Checkbox from 'components/Form/Checkbox';
import StepHeader from 'components/StepHeader';
import SafeHtml from 'components/Utils';
import React, { memo } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { createSelectorID, wait } from 'utils';
import * as yup from 'yup';
import injectQuestionnaireVariables from '../../../utils/injectQuestionnaireVariables';

function Question({ forwardRef, step, settings, submit, setLoader }) {
  const { autoJump } = settings;
  const { answers = [], required_text, required = false, salesforce, multi } = step;
  const isRequired = required && required_text;

  const QuestionSchema = yup.object().shape({
    // TODO: migrate all to array
    [salesforce]: multi ? yup.array().of(yup.string()).required(required_text) : yup.string().required(required_text),
  });

  const methods = useForm({
    defaultValues: {
      [salesforce]: answers.filter((item) => item.checked).map((item) => item.salesforce),
    },
    mode: 'onSubmit',
    resolver: yupResolver(QuestionSchema),
    shouldFocusError: false,
  });

  const {
    setValue,
    register,
    control,
    errors,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const watchedValues = useWatch({
    control,
    name: salesforce,
  });

  const isAnswered = watchedValues.length > 0;

  async function submitQuestion(values) {
    if (isSubmitting) {
      return;
    }

    await wait(values ? 150 : 0); // Artificial delay
    submit(values);
  }

  function toggleAnswers({ salesforce: salesforceAnswer }) {
    answers.forEach((answer) => {
      // TODO: don't mutate checked. Currently getting next index logic is based on "checked". Need to replace it.
      answer.checked = answer.salesforce === salesforceAnswer;
    });
  }

  function onSubmit(event) {
    event.preventDefault();

    if (isRequired || isAnswered) {
      handleSubmit(submitQuestion)(event);
    } else {
      submit({ [salesforce]: null });
    }
  }

  function onMultiAnswerChange(event, newValue) {
    const { name, checked } = event.target;
    const currentValues = watchedValues;
    const index = currentValues.indexOf(newValue);
    const copiedAnswers = [...currentValues];

    if (!checked) {
      copiedAnswers.splice(index, 1);
    }

    const answeredItem = answers.find((item) => item.salesforce === newValue);
    toggleAnswers(answeredItem);

    const newValues = checked ? [...currentValues, newValue] : copiedAnswers;
    setValue(name, newValues, { shouldDirty: true });
  }

  function onChange(event, newValue) {
    if (isSubmitting) return;

    const { name, checked } = event.target;

    if (autoJump && !checked) {
      // If autojump is true, and the answer is preselected it's treated as a confirmation.
      // Don't uncheck it but submit an answer further
      setValue(name, newValue, { shouldDirty: true });
    } else {
      setValue(name, checked ? newValue : '', { shouldDirty: true });
    }

    const answeredItem = answers.find((item) => item.salesforce === newValue);
    toggleAnswers(answeredItem);

    if (answeredItem.loader) {
      setLoader(answeredItem.loader);
    }

    if (autoJump) {
      handleSubmit(submitQuestion)();
    }
  }

  const count = answers.length;

  return (
    <form ref={forwardRef} onSubmit={onSubmit} noValidate autoComplete="off">
      <StepHeader step={step} errors={errors} name={salesforce} />
      {step.body && <SafeHtml html={injectQuestionnaireVariables(step.body)} />}
      <div className={`Question ${count > 0 ? `col-${count}` : ''}`}>
        <div className="Container">
          {step.answers.map((answer) => {
            const checked = watchedValues.indexOf(answer.salesforce) !== -1;
            const labelId = createSelectorID({ id: salesforce, salesforce: answer.salesforce });

            return (
              <Checkbox
                key={labelId}
                ref={register()}
                id={answer.salesforce}
                hideError
                disabled={!multi && checked && !autoJump}
                title={injectQuestionnaireVariables(answer.title)}
                onChange={(event) => {
                  if (multi) {
                    onMultiAnswerChange(event, answer.salesforce);
                  } else {
                    onChange(event, answer.salesforce);
                  }
                }}
                name={salesforce}
                value={answer.salesforce}
                className={multi ? 'CheckboxAnswer' : 'RadioAnswer'}
                inputClassName="form-control"
                labelId={labelId}
                labelClassName={`answer-button ${checked ? 'checked' : ''}`}
              />
            );
          })}
        </div>
      </div>
    </form>
  );
}

export default memo(Question);
