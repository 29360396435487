import { useEffect, useState } from 'react';
import { cssValueToInt, isQuestion, throttle } from '../../utils';

export default function useSliderHeightCalculation(settings, steps, stepsRef, sliderRef, footerRef) {
  const [height, setHeight] = useState({ maxHeight: null, minHeight: null });

  function getExtraSliderHeight(node) {
    const styles = getComputedStyle(node);
    return cssValueToInt(styles.marginTop) + cssValueToInt(styles.paddingTop) + cssValueToInt(styles.marginBottom);
  }

  function getStepHeight(uuid) {
    return stepsRef[uuid] && stepsRef[uuid].offsetHeight;
  }

  function calculateHeight() {
    if (Object.keys(stepsRef).length === 0) return;

    const maxStepsHeight = steps.reduce((max, { uuid }) => Math.max(getStepHeight(uuid), max), 0);
    const footerHeight = (footerRef.current && footerRef.current.offsetHeight) || 0;
    const extraSliderHeight = getExtraSliderHeight(sliderRef.current);

    let maxStepHeight = 0;
    let maxHeight = 0;

    steps.forEach((step) => {
      const stepHeight = getStepHeight(step.uuid);

      maxStepHeight = Math.max(maxStepHeight, stepHeight); // to keep the FC's height the same when footer is hidden

      if (isQuestion(step)) {
        maxHeight = Math.max(stepHeight, maxHeight); // to position next btn
      }
    });

    const minHeight = maxStepHeight + extraSliderHeight + footerHeight;

    if (maxStepsHeight > 0) {
      setHeight({ maxHeight, minHeight });
    }
  }

  const throttledResize = throttle(calculateHeight, 150);

  useEffect(() => {
    calculateHeight();
  }, [stepsRef, footerRef, sliderRef]);

  useEffect(() => {
    // When the font changes (e.g. to the custom one) - the height of the elements can be changed.
    // Need to recalculate the height of the Q
    if (document.fonts) {
      document.fonts.ready.then(() => {
        calculateHeight();
      });
    }

    window.addEventListener('resize', throttledResize);

    return () => {
      window.removeEventListener('resize', throttledResize);
    };
  }, []);

  return { height };
}
