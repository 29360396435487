import React, { memo } from 'react';
import ErrorMessage from '../ErrorMessage';
import Label from '../Label';

function RadioGroup({ labelClassName, onChange, className = '', name, hasError, options }, ref) {
  const optionsParsed = JSON.parse(options || '[]');

  return (
    <div className={`${className} ${hasError ? 'invalid' : ''}`}>
      <input type="hidden" name={name} ref={ref} />
      <div className="Error">
        <ErrorMessage name={name} />
      </div>
      {optionsParsed.map(({ title: label, salesforce: value }) => (
        <Label key={value} id={`${name}.${value}`} className={`${labelClassName} ${labelClassName}--${value}`}>
          <span className="InputContainer">
            <input type="radio" onChange={(e) => onChange(e.target.name, e.target.value)} name={name} value={value} />
            <span className="TextLabel">{label}</span>
          </span>
        </Label>
      ))}
    </div>
  );
}

export default memo(React.forwardRef(RadioGroup));
