import appConfig from 'configuration';
import { objectToQueryParams } from '../../utils';

const API_KEY = 'SQU3CPCLYGH95TS6YX13';

const DEFAULT_OPTIONS = {
  key: API_KEY,
  timeout: appConfig.postalCodeTimeout,
};

class ZipCodes {
  constructor(options) {
    this.options = { ...DEFAULT_OPTIONS, ...options };
    this.API = 'https://api.zip-codes.com/ZipCodesAPI.svc/1.0';
  }

  getUrl(endpoint, params) {
    // TODO: replace with URLSearchParams
    const query = objectToQueryParams(params);
    return `${this.API}${endpoint}?${query}`;
  }

  request(url) {
    const { customFetch, timeout } = this.options;
    const fetch = customFetch || window.fetch;
    const options = {
      cache: 'no-cache',
      method: 'GET',
      mode: 'cors',
    };

    const request = customFetch ? customFetch(url, options, timeout) : fetch(url, options);

    return request
      .then((res) => res.json())
      .catch(() => {
        // TODO: Error handling
        // console.log(error);
      });
  }

  checkPostalCode(postalCode) {
    const { key } = this.options;
    const endpoint = `/QuickGetZipCodeDetails/${postalCode}`;
    const url = this.getUrl(endpoint, { key });

    return this.request(url);
  }
}

export default ZipCodes;
