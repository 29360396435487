import React from 'react';
import SafeHtml from '../Utils';

export default function PrivacyText({ html }) {
  return (
    <div className="Container PrivacyTextContainer">
      <i className="icon fa-lock" />
      <span className="TextLabel PrivacyText">
        <SafeHtml html={html} />
      </span>
    </div>
  );
}
